import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import s from './Quote.module.sass'

export function Quote({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div
			id={`section-${reference.block?.order}`}
			className={clsx(
				s.QuoteBox,
				reference.darkBackground && s.DarkBackground,
				reference.withHeadline && s.WithHeadline,
				'maybePaddingRight'
			)}>
			<div className={s.QuoteBoxContainer}>
				<h3 className={s.Title}>{reference.tertiaryText}</h3>
				<div className={clsx(reference.withHeadline && s.WithHeadlineBox)}>
					<h3 className={clsx(s.Text, reference.withHeadline && s.WithHeadlineText)}>
						<span
							className={clsx(
								s.QuotationMarks,
								reference.withHeadline && s.WithHeadlineQuestionMarkStart
							)}>
							„
						</span>
						{reference.primaryText}
						<span
							className={clsx(
								s.QuotationMarks,
								reference.withHeadline && s.WithHeadlineQuestionMarkEnd
							)}>
							“
						</span>
					</h3>
					<h5 className={clsx(s.Author, reference.withHeadline && s.WithHeadlineAuthor)}>
						{reference.secondaryText}
					</h5>
				</div>
			</div>
		</div>
	)
}
