import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import SvgArrowDown from '../../../svg/sprites/ArrowDown'
import SvgArrowDownload from '../../../svg/sprites/ArrowDownload'
import s from './Timeline.module.sass'

export function Timeline({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	const [isOpen, setIsOpen] = React.useState(false)
	const items = isOpen ? reference.items : [...reference.items].slice(0, 3)
	return (
		<div id={`section-${reference.block?.order}`} className={s.Timeline}>
			<div className={s.TimelineContainer}>
				<div className={s.TitleBox}>
					<div className={s.Title}>{reference.primaryText}</div>
					<SvgArrowDown />
				</div>
				<div className={s.TimelineBoxes}>
					{items.map((item, i) => (
						<div className={s.TimelineBox} key={i}>
							<p className={s.Headline}>{item.headline}</p>
							<p className={s.Text}>{item.text}</p>
						</div>
					))}
				</div>
				{reference.items.length > 3 && !isOpen && (
					<button className={s.Button} onClick={() => setIsOpen(true)}>
						Celá Historie <SvgArrowDownload />
					</button>
				)}
			</div>
		</div>
	)
}
