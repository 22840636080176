import clsx from 'clsx'
import 'collapsible-react-component/dist/index.css'
import Link from 'next/link'
import { useMemo } from 'react'
import s from './Pagination.module.sass'

export type PaginationProps = {
	current: number
	maxPage: number
}

function PaginationLink(props: { page: number }) {
	// @TODO make it work on server side
	const url = new URL(window.location.href)
	url.searchParams.set('p', props.page.toString())

	return (
		<Link
			href={url.toString().split('+').join('%20').split('%28').join('(').split('%29').join(')')}>
			<a className={s.link}>{props.page}</a>
		</Link>
	)
}

export function Pagination(props: PaginationProps) {
	const stepSize = 3

	const items = useMemo(() => {
		const previousItems: number[] = []
		const nextItems: number[] = []
		if (props.current - stepSize === 3) {
			previousItems.push(2)
		}
		for (let i = props.current - stepSize; i < props.current; i++) {
			if (i > 1) {
				previousItems.push(i)
			}
		}
		for (let i = props.current + 1; i < props.current + stepSize + 1; i++) {
			if (i < props.maxPage) {
				nextItems.push(i)
			}
		}
		if (props.current + stepSize === props.maxPage - 2) {
			nextItems.push(props.current + stepSize + 1)
		}
		return { previous: previousItems, next: nextItems }
	}, [props])

	return (
		<div className={s.wrapper}>
			<ul className={s.list}>
				{props.current !== 1 && (
					<div className={s.item}>
						<PaginationLink page={1} />
					</div>
				)}
				{items.previous.length > 0 && items.previous[0] !== 2 && <span className={s.empty}>…</span>}
				{items.previous.map((item) => (
					<div key={`previous-${item}`} className={s.item}>
						<PaginationLink page={item} />
					</div>
				))}
				<div className={clsx(s.item, s.isActive)}>
					<PaginationLink page={props.current} />
				</div>
				{items.next.map((item) => (
					<div key={`next-${item}`} className={s.item}>
						<PaginationLink page={item} />
					</div>
				))}
				{items.next.length > 0 && items.next[items.next.length - 1] !== props.maxPage - 1 && (
					<span className={s.empty}>…</span>
				)}
				{props.current !== props.maxPage && (
					<div className={s.item}>
						<PaginationLink page={props.maxPage} />
					</div>
				)}
			</ul>
		</div>
	)
}
