import React, { useState } from 'react'
import { createFetcher } from '../../packages/@necktip/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import type { NewsletterResult } from '../data/content/NewsletterFragment'
import { NewsletterFormInput } from '../forms/NewsletterForm/NewsletterFormInput'
import { sendDataLayerFormSent } from '../utils/sendDataLayerFormSent'
import { validateZod } from '../validators/utils/validateZod'
import { Form } from './Form'
import s from './NewsletterForm.module.sass'

const api = createFetcher<API>('/api/v1/[handler]')

export type NewsletterFormProps = {
	newsletter: NewsletterResult
	file?: {
		name?: string
		url?: string
		fileType?: string
	}
	hiddenTitle?: boolean
	isCounselingForm?: boolean
	formFieldsHidden?: boolean
	redirectToSalesforce?: boolean
}

export function NewsletterForm(props: NewsletterFormProps) {
	const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>({})
	const [submitEnabled, setSubmitEnabled] = useState(true)

	return props.newsletter.form ? (
		<div className={s.wrapper}>
			<Form
				title={!props.hiddenTitle && props.newsletter.localesByLocale?.title}
				errorMessages={errorMessages}
				submitEnabled={submitEnabled}
				form={props.newsletter.form}
				formFieldsHidden={props.formFieldsHidden}
				redirectToSalesforce={props.redirectToSalesforce}
				handleSubmit={async (event) => {
					event.preventDefault()
					setSubmitEnabled(false)
					let formErrors: { [key: string]: string } = {}

					if (event.target instanceof HTMLFormElement) {
						const formData = new FormData(event.target)
						const data = Object.fromEntries(formData.entries())

						if (data.email) {
							const validatedData = validateZod(
								{
									email: data.email,
									newsletter: props.newsletter.id,
									fileName: props.file?.name || props.file?.url || '',
									fileUrl: props.file?.url || '',
								},
								NewsletterFormInput()
							)
							const response = await api('newsletterForm', validatedData)
							// if (response.mailStatus !== undefined && !response.mailStatus.ok) {
							// 	// @TODO uncomment to prevent form from submitting on mailing error
							// 	// formErrors = {
							// 	// 	...formErrors,
							// 	// 	mailStatus:
							// 	// 		response.mailStatus.errorMessage || 'Nepodařilo se odeslat email se souborem.',
							// 	// }
							// 	console.error(
							// 		response.mailStatus.errorMessage || 'Nepodařilo se odeslat email se souborem.'
							// 	)
							// }
							if (!response.result.createNewsletterSubscription.ok) {
								console.error(
									response.result.createNewsletterSubscription.errorMessage ||
										'Error while adding user email to db.'
								)
							}
						}

						if (props.isCounselingForm) {
							const regions = {
								PHA: 'Praha',
								JHC: 'Jihočeský kraj',
								JHM: 'Jihomoravský kraj',
								KVK: 'Karlovarský kraj',
								VYS: 'Vysočina',
								HKK: 'Královehradecký kraj',
								LBK: 'Liberecký kraj',
								MSK: 'Moravskoslezský kraj',
								OLK: 'Olomoucký kraj',
								PAK: 'Pardubický kraj',
								PLK: 'Plzeňský kraj',
								STC: 'Středočeský kraj',
								ULK: 'Ústecký kraj',
								ZLK: 'Zlínský kraj',
							}
							const stateCode = formData.get('state_code')
							const region = Object.entries(regions).find(([code]) => code === stateCode)
							const response = await fetch('/api/poradna/questions', {
								method: 'POST',
								body: JSON.stringify({
									email: formData.get('email'),
									name: formData.get('first_name'),
									surname: formData.get('last_name'),
									phone: formData.get('phone'),
									region: region ? region[1] : null,
									agreement: formData.get('agreement') !== null,
									newsletter: formData.get('00N0900000JRXK9') !== null,
									question: formData.get('question'),
									person_target: '',
								}),
							})
							const data = await response.json()

							if (data.meta.status !== 'OK') {
								formErrors = {
									...formErrors,
									...data.meta.error_msg,
								}
							}
						}

						setErrorMessages(formErrors)

						if (Object.keys(formErrors).length > 0) {
							setSubmitEnabled(true)

							sendDataLayerFormSent(
								props.newsletter.form?.gaDatalayerIdentifier ?? 'nezarazenyFormular'
							)

							return
						} else {
							event.target.submit()
						}
					}
				}}
			/>
		</div>
	) : (
		<></>
	)
}
