import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'
import type { BlogPostResult } from '../data/content/BlogFragment'
import ArrowSvg from '../svg/sprites/Arrow'
import s from './BlogPost.module.sass'

export function Blog(props: {
	post: BlogPostResult
	showThumbnail?: boolean
	isBlogPage?: boolean
}) {
	const post = props.post
	const showThumbnail = props.showThumbnail
	const isBlogPage = props.isBlogPage
	const classThumb = showThumbnail ? [] : [s.HideThumbnail]
	const tileImage =
		props.post.localesByLocale?.tileImage ?? props.post.localesByLocale?.cover?.image

	return (
		<a
			href={post?.localesByLocale?.link?.url}
			className={clsx(
				s.BlogPost,
				isBlogPage && s.isBlogPage,
				!showThumbnail && s.withoutThumbnail
			)}>
			<div className={s.TextBox}>
				<h3 className={s.BlogPostTitle}>{post?.localesByLocale?.cover?.headlineTop}</h3>
				<p className={s.BlogPostSubtitle}>{post?.localesByLocale?.perex}</p>
				<div className={s.ButtonBox}>
					<p>Číst celý článek</p>
					<span className={s.Arrow}>
						<ArrowSvg />
					</span>
				</div>
			</div>
			<div className={clsx(s.BlogPostImage, classThumb)}>
				<div className={s.Wrapper}>
					{tileImage?.url && tileImage?.width && tileImage?.height && (
						<Image alt="" src={tileImage.url} layout="fill" objectFit="cover" />
					)}
				</div>
			</div>
		</a>
	)
}
