import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import ArrowSvg from '../../../svg/sprites/Arrow'
import { Link } from '../../Link'
import s from './Buttons.module.sass'

export function Buttons({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.Buttons}>
			{reference.link && (
				<Link link={reference.link} className={s.Button}>
					<span className={s.Title}>{reference.link?.title}</span>
					<ArrowSvg />
				</Link>
			)}

			{reference.linkSecond && (
				<Link link={reference.linkSecond} className={s.Button}>
					<span className={s.Title}>{reference.linkSecond?.title}</span>
					<ArrowSvg />
				</Link>
			)}
		</div>
	)
}
