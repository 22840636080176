/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import { default as clsx, default as cn } from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { BannerType, LinkType } from '../../../../generated/content'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import SvgArrow from '../../../svg/sprites/Arrow'
import { sendDataLayerFormSent } from '../../../utils/sendDataLayerFormSent'
import { ArrowLink } from '../../ArrowLink'
import { ModalWrapper } from '../../ModalWrapper'
import { NewsletterForm } from '../../NewsletterForm'
import s from './Banners.module.sass'

export function Banners({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	const hasBackgroundWhite = reference.banners.some(
		(item) => item.banner?.type === BannerType.fullWithTwoImages && item.banner.highlight
	)
	const hasBackground = reference.banners.some(
		(item) =>
			item.banner?.type === BannerType.full || item.banner?.type === BannerType.fullNewsletter
	)

	return (
		<div
			id={`section-${reference.block?.order}`}
			className={clsx(s.Banners, reference.banners.length > 1 && s.BannersBox)}>
			{reference.banners.map((item, i) => (
				<div
					key={i}
					className={clsx(
						hasBackgroundWhite ? s.BoxWhite : undefined,
						hasBackground ? s.BannerBox : undefined,
						s[item.banner?.type ?? '']
					)}>
					<Banner item={item} />
				</div>
			))}
		</div>
	)
}

function Banner(props: { item: ContentBlockReference['banners'][number] }) {
	const router = useRouter()
	const banner = props.item.banner

	const formId = props.item.banner?.localesByLocale?.newsletter?.form?.id
	const gaDatalayerIdentifier =
		props.item.banner?.localesByLocale?.newsletter?.form?.gaDatalayerIdentifier
	const isSuccess = Boolean(formId && router.query.success === formId)

	useEffect(() => {
		if (isSuccess) {
			sendDataLayerFormSent(gaDatalayerIdentifier ?? 'nezarazenyFormular')
		}
	}, [isSuccess, gaDatalayerIdentifier])

	const [isOpen, setIsOpen] = React.useState(isSuccess)

	if (!banner) {
		return null
	}

	const primaryImage = (
		<div className={s.PrimaryImage}>
			{banner?.primaryImage?.url && banner.primaryImage?.width && banner.primaryImage?.height && (
				<Image
					alt=""
					src={banner.primaryImage.url}
					width={banner.primaryImage.width}
					height={banner.primaryImage.height}
				/>
			)}
		</div>
	)

	const secondaryImage = (
		<div className={s.SecondaryImage}>
			{banner?.secondaryImage?.url &&
				banner.secondaryImage?.width &&
				banner.secondaryImage?.height && (
					<Image
						alt=""
						src={banner.secondaryImage.url}
						width={banner.secondaryImage.width}
						height={banner.secondaryImage.height}
					/>
				)}
		</div>
	)

	const primaryLinkData = banner?.localesByLocale?.primaryLink
	const primaryLink = primaryLinkData && (
		<ArrowLink
			link={primaryLinkData}
			text={primaryLinkData.title ?? ''}
			isDownload={primaryLinkData.type === LinkType.download}
		/>
	)
	const secondaryLinkData = banner.localesByLocale?.secondaryLink
	const secondaryLink = secondaryLinkData && (
		<ArrowLink
			link={secondaryLinkData}
			text={secondaryLinkData?.title ?? ''}
			isDownload={secondaryLinkData.type === LinkType.download}
		/>
	)

	const linkListData = banner?.localesByLocale?.linkList?.items
	const linkList = (
		<>
			{linkListData &&
				linkListData.map((item, i) => (
					<ArrowLink
						key={i}
						link={item.link}
						text={item.link?.title ?? ''}
						isDownload={item.link?.type === LinkType.download}
					/>
				))}
		</>
	)

	const primaryText = <h1 className={s.PrimaryText}>{banner?.localesByLocale?.primaryText}</h1>

	const secondaryText = <p>{banner?.localesByLocale?.secondaryText}</p>

	const tertiaryText = <p>{banner?.localesByLocale?.tertiaryText}</p>

	const highlight = banner.highlight
	const classes = highlight ? [s.Highlighted] : []

	switch (banner.type) {
		case BannerType.oneThird:
			return (
				<div className={cn(s.OneThird, classes, s.Banner)}>
					<div className={s.TextBox}>
						<div>{primaryText}</div>
						<div>{primaryLink}</div>
					</div>
					<div className={s.ImageBox}>{primaryImage}</div>
				</div>
			)
		case BannerType.oneThirdWithEmbed:
			return (
				<div className={cn(s.OneThirdWithEmbed, classes, s.Banner)}>
					<div className={s.TitleBox}>
						<div>{primaryText}</div>
						<div>{primaryImage}</div>
					</div>
					{tertiaryText && (
						<div className={s.TextBox}>
							<div>{tertiaryText}</div>
						</div>
					)}
					{banner?.localesByLocale?.secondaryText && (
						<iframe
							width="100%"
							height={banner?.localesByLocale?.height ?? '251'}
							src={banner?.localesByLocale?.secondaryText}
							scrolling="no"
							className={s.iFrame}
							title="iFrame"
						/>
					)}
				</div>
			)
		case BannerType.oneThirdWithButton:
			return (
				<div className={cn(s.OneThirdWithButton, classes, s.Banner)}>
					<div>{primaryText}</div>
					<div>{secondaryText}</div>
					<div>{primaryImage}</div>
					<div className={s.Link}>{primaryLink}</div>
				</div>
			)
		case BannerType.oneThirdWithLinks:
			return (
				<div className={cn(s.OneThirdWithLinks, classes, s.Banner)}>
					<div>{primaryText}</div>
					<div className={s.Link}>{linkList}</div>
				</div>
			)
		case BannerType.oneThirdWithTwoLinks:
			return (
				<div className={cn(s.OneThirdWithTwoLinks, classes, s.Banner)}>
					<div>{primaryText}</div>
					<div>{secondaryText}</div>
					<div>{primaryLink}</div>
					<div>{secondaryLink}</div>
				</div>
			)
		case BannerType.twoThird:
			return (
				<div className={cn(s.TwoThird, s.Banner)}>
					<div className={s.Box}>
						<div className={s.TextBox}>
							<div>{primaryText}</div>
							<div>{secondaryText}</div>
						</div>
						<div className={s.ImageBox}>{primaryImage}</div>
					</div>
					<div className={s.Links}>
						<div className={s.Link}>{primaryLink}</div>
						<div className={s.Link}>{secondaryLink}</div>
					</div>
				</div>
			)
		case BannerType.full:
			return (
				<div className={cn(s.Full, s.Banner)}>
					<div className={s.PrimaryBox}>{primaryText}</div>
					<div className={s.SecondaryBox}>{primaryLink}</div>
					<div className={s.ImageBox}>{primaryImage}</div>
				</div>
			)
		case BannerType.fullNewsletter:
			return (
				<div className={clsx(s.FullNewsletterWrapper, isOpen && s.isOpen)}>
					<div className={cn(s.Full, s.Banner, s.FullNewsletter)}>
						<div className={s.PrimaryBox}>{primaryText}</div>
						<div className={s.SecondaryBox}>
							<button
								className={clsx(s.NewsletterLink, 'gtm_click2modal')}
								onClick={() => {
									setIsOpen((open) => {
										return !open
									})
								}}>
								{secondaryText}
							</button>
							<span className={s.Arrow}>
								<SvgArrow />
							</span>
						</div>
						<div className={s.ImageBox}>{primaryImage}</div>
					</div>
					<ModalWrapper isOpen={isOpen} closeModal={() => setIsOpen(false)}>
						<div className={s.NewsletterForm}>
							<div className={s.NewsletterFormContainer}>
								<div className={s.NewsletterFormTextBox}>
									{props.item.banner?.localesByLocale?.newsletter && (
										<NewsletterForm
											redirectToSalesforce
											newsletter={props.item.banner.localesByLocale.newsletter}
										/>
									)}
								</div>
								<div className={clsx(s.PrimaryImage, s.NewsletterImage)}>
									{props?.item?.banner?.localesByLocale?.newsletter?.primaryImage?.url &&
										props.item.banner.localesByLocale.newsletter.primaryImage.width &&
										props.item.banner.localesByLocale.newsletter.primaryImage.height && (
											<Image
												alt=""
												src={props.item.banner.localesByLocale.newsletter.primaryImage.url}
												width={props.item.banner.localesByLocale.newsletter.primaryImage.width}
												height={props.item.banner.localesByLocale.newsletter.primaryImage.height}
											/>
										)}
								</div>
							</div>
						</div>
					</ModalWrapper>
				</div>
			)
		case BannerType.twoThirdNewsletter:
			return (
				<div className={cn(s.TwoThird, s.Banner, s.TwoThirdNewsletter)}>
					<div className={s.Box}>
						<div className={s.TextBox}>
							<div>{primaryText}</div>
							<div>{secondaryText}</div>
						</div>
						<div className={s.ImageBox}>{primaryImage}</div>
					</div>
					<div className={s.Links}>
						<div className={s.SecondaryBox}>
							<button
								className={clsx(s.NewsletterLink, 'gtm_click2modal')}
								onClick={() => {
									setIsOpen((open) => {
										return !open
									})
								}}>
								{tertiaryText}
							</button>
							{props?.item?.banner?.localesByLocale?.newsletter && (
								<span className={s.Arrow}>
									<SvgArrow />
								</span>
							)}
						</div>
					</div>
					<ModalWrapper isOpen={isOpen} closeModal={() => setIsOpen(false)}>
						<div className={s.NewsletterForm}>
							<div className={s.NewsletterFormContainer}>
								<div className={s.NewsletterFormTextBox}>
									{props.item.banner?.localesByLocale?.newsletter && (
										<NewsletterForm
											redirectToSalesforce
											newsletter={props.item.banner.localesByLocale.newsletter}
										/>
									)}
								</div>
								<div className={clsx(s.PrimaryImage, s.NewsletterImage)}>
									{props?.item?.banner?.localesByLocale?.newsletter?.primaryImage?.url &&
										props.item.banner.localesByLocale.newsletter.primaryImage.width &&
										props.item.banner.localesByLocale.newsletter.primaryImage.height && (
											<Image
												alt=""
												src={props.item.banner.localesByLocale.newsletter.primaryImage.url}
												width={props.item.banner.localesByLocale.newsletter.primaryImage.width}
												height={props.item.banner.localesByLocale.newsletter.primaryImage.height}
											/>
										)}
								</div>
							</div>
						</div>
					</ModalWrapper>
					<div className={s.Link}>{primaryLink}</div>
					<div className={s.Link}>{secondaryLink}</div>
				</div>
			)

		case BannerType.fullWithTwoImages:
			return (
				<section className={clsx(s.FullWithTwoImages, highlight && s.WhiteBackground)}>
					<div className={s.Box}>{primaryImage}</div>
					<div className={clsx(s.TextBox, s.Box)}>
						<div className={clsx(s.PrimaryText, s.TwoImagesBox)}>{primaryText}</div>
						{secondaryText && <span className={s.PrimaryText}>–</span>}
						<div className={clsx(s.PrimaryText, s.TwoImagesBox)}>{secondaryText}</div>
						<span className={s.TertiaryText}>
							<RichTextRenderer source={banner.localesByLocale?.tertiaryText ?? null} />
						</span>
						<div className={s.Link}>{primaryLink}</div>
					</div>
					<div className={s.Box}>{secondaryImage}</div>
				</section>
			)
	}
	return null
}
