/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { ArrowLink } from './ArrowLink'
import { BlockRenderer } from './blocks/BlockRenderer'
import s from './Course.module.sass'

export type CourseProps = {
	course: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['course']>
}

export function Course(props: CommonPageProps & CourseProps) {
	// const firstDate = (props.course.root?.dates || [])[0]

	const [open, setOpen] = React.useState(false)
	React.useEffect(() => {
		const onScroll = () => {
			document.documentElement.classList.toggle('scrolledDown', window.scrollY > 300)
		}
		window.addEventListener('scroll', onScroll, { passive: true })

		return () => {
			document.documentElement.classList.remove('scrolledDown')
			window.removeEventListener('scroll', onScroll)
		}
	}, [])
	return (
		<>
			{props.course.published && (
				<>
					<div className={s.Course}>
						<div className={s.ImageBox}>
							<div className={s.CourseImage}>
								{props.course.root?.coverImage?.url && (
									<Image
										alt=""
										src={props.course.root?.coverImage.url}
										layout="fill"
										objectFit="cover"
									/>
								)}
							</div>
						</div>
						{props.course.root?.header && (
							<div className={s.Header}>
								{props.course.root.header.localesByLocale?.items.map((item) => (
									<React.Fragment key={item.id}>
										<div className={s.HeaderImage}>
											{item?.image?.url && item?.image.width && item?.image.height && (
												<Image
													alt=""
													src={item.image.url}
													width={item.image.width}
													height={item.image.height}
												/>
											)}
										</div>
										{item?.textColumnFirst && (
											<p className={s.HeaderColumn}>{item.textColumnFirst}</p>
										)}
										{item.textColumnSecond && (
											<p className={s.HeaderColumn}>{item.textColumnSecond}</p>
										)}
									</React.Fragment>
								))}
							</div>
						)}
						<div className={s.ContentBox}>
							<BlockRenderer alignLeft={true} blocks={props.course.content?.blocks ?? []} />
						</div>

						<div className={s.LoginBoxWrap}>
							<div className={s.StickyBox}>
								<div
									onClick={() => {
										setOpen((open) => {
											return !open
										})
									}}
									className={clsx(s.LoginBox, open && s.isOpen)}>
									<div className={s.LoginTitleBoxWrapper}>
										<p className={s.LoginTitleBox}>
											<p className={clsx(s.LoginTitleBox, s.TitleTop)}>{props.course.title}</p>
											{props.course.titleBottom && <span className={s.Dash}>—</span>}
											<p className={clsx(s.LoginTitleBox, s.TitleBottom)}>
												{props.course.titleBottom}
											</p>
										</p>

										{/* <div className={s.InfoBox}>
								{firstDate && (
									<p className={clsx(s.Date, s.InfoValue)}>
										{new Intl.DateTimeFormat('cs', {
											day: 'numeric',
											month: 'numeric',
											year: 'numeric',
										}).format(new Date(firstDate.date))}
									</p>
								)}
								{props.course.root?.time && (
									<div className={s.InfoValue}>
										<p>{props.course.root?.time?.from}</p>
										<span className={s.Dash}>-</span>
										<p>{props.course.root?.time?.to}</p>
									</div>
								)}
							</div> */}
									</div>
									{props.course.loginLink && (
										<div className={s.LoginLink}>
											<ArrowLink
												link={props.course.loginLink}
												text={props.course.loginLink?.title ?? ''}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}
