import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import type { CommonPageProps } from '../../pages/[[...page]]'
import s from './Footer.module.sass'
import { Link } from './Link'

export type FooterProps = {
	footer: NonNullable<CommonPageProps['data']['getFooterMenuLocale']>
}

export function Footer(props: FooterProps) {
	const socialLinks = (
		<>
			{props.footer.socialLinks &&
				props.footer.socialLinks.map((item, i) => (
					<div key={i} className={s.SocialLink}>
						{item.link && (
							<Link name="Socials" link={item.link}>
								{item.icon && item.icon?.width && item.icon?.height && (
									<Image
										alt=""
										src={item.icon.url}
										width={item.icon.width}
										height={item.icon.height}
									/>
								)}
							</Link>
						)}
					</div>
				))}
		</>
	)
	return (
		<div className={s.Footer}>
			<div className={s.FooterBox}>
				<div className={s.FooterTextBox}>
					{props.footer.columns.map((item, i) => (
						<div className={s.Box} key={i}>
							<RichTextRenderer source={item.text ?? null} />
							<div className={s.SocialLinks}>{i === 0 && socialLinks}</div>
						</div>
					))}
					<span className={s.FooterLogo}>
						{props.footer.image?.url && props.footer.image?.width && props.footer.image?.height && (
							<Image
								alt=""
								src={props.footer.image.url}
								width={props.footer.image.width}
								height={props.footer.image.height}
							/>
						)}
					</span>
				</div>

				<div className={s.SocialLinksMobile}>{socialLinks}</div>
			</div>
		</div>
	)
}
