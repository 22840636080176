import type { ReferenceRendererProps } from '@contember/react-client'
import cn from 'clsx'
import React from 'react'
import { ContentType } from '../../../../generated/content'
import { usePageContext } from '../../../../pages/[[...page]]'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import { filterNonEmpty } from '../../../utils/filterNonEmpty'
import { ArrowLink } from '../../ArrowLink'
import { CourseTile } from '../../CourseTile'
import s from './Courses.module.sass'

export function Courses({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	const extras = usePageContext().extras

	const allCourses = reference.topic ? extras.topicCourses[reference.topic.id] : extras.allCourses

	const rawCourses =
		reference.courseType === ContentType.all
			? (allCourses ?? []).map((post) => post.root)
			: reference.courses.map((post) => post.course)

	const courses = rawCourses.filter(filterNonEmpty)

	const darkBackground = reference.darkBackground
	const classes = darkBackground ? [s.DarkBackground] : []
	return (
		<div
			id={`section-${reference.block?.order}`}
			className={cn(s.CoursesBox, classes, 'maybePaddingRight')}>
			<div className={s.TitleBox}>
				<h3 className={s.Title}>{reference.primaryText}</h3>
				{reference.secondaryText && <span className={cn(s.Title, s.Dash)}>—</span>}
				<h3 className={s.Title}>{reference.secondaryText}</h3>
			</div>
			<div className={s.Courses}>
				{courses.map((course, i) => (
					<>
						{course.localesByLocale?.published && (
							<a
								href={course.localesByLocale?.externalUrl || course.localesByLocale?.link?.url}
								className={s.Course}
								key={i}>
								<CourseTile course={course} />
							</a>
						)}
					</>
				))}
			</div>
			{reference.link?.title && (
				<div className={s.ButtonBox}>
					<ArrowLink link={reference.link} text={reference.link.title} />
				</div>
			)}
		</div>
	)
}
