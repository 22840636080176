import { CommonPageProps, usePageContext } from '../../pages/[[...page]]'
import s from './BlogPage.module.sass'
import { Blog } from './BlogPost'
import { Cover } from './Cover'

export type blogProps = {
	blogPage: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['blogPage']>
}

export function BlogPage(props: CommonPageProps & blogProps) {
	const allBlogPosts = usePageContext().extras.allBlogPosts

	return (
		<div className={s.BlogPage}>
			<div className={s.Cover}>
				{props.blogPage.cover && <Cover cover={props.blogPage.cover} />}
			</div>
			<div className={s.BlogPosts} data-multiline="true">
				<div className={s.Posts}>
					{allBlogPosts?.map(
						(post, i) =>
							post.root &&
							post.root.localesByLocale?.published && (
								<Blog isBlogPage={true} showThumbnail={true} post={post.root} key={i} />
							)
					)}
				</div>
			</div>
		</div>
	)
}
