import type { ReferenceRendererProps } from '@contember/react-client'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import clsx from 'clsx'
import Image from 'next/image'
import { ContentType } from '../../../../generated/content'
import { usePageContext } from '../../../../pages/[[...page]]'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import { filterNonEmpty } from '../../../utils/filterNonEmpty'
import { Link } from '../../Link'
import s from './Partners.module.sass'

export function Partners({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	const allPartners = usePageContext().extras.allPartners

	const partnerType = reference.partnerType
	const partners =
		partnerType === ContentType.selected
			? reference.partners.map((p) => p.partner).filter(filterNonEmpty)
			: allPartners
	const classPartner = partnerType === ContentType.selected ? [s.SelectedPartners] : [s.AllPartners]

	const highlight = reference.highlight
	const classes = highlight ? [s.Highlighted] : []

	return (
		<div className={clsx(s.PartnersContainer, classes)}>
			<div id={`section-${reference.block?.order}`} className={s.PartnersBox}>
				<Splide
					options={{
						gap: '1rem',
						perPage: 4,
						perMove: 1,
						pagination: false,
						breakpoints: {
							992: {
								perPage: 3,
								gap: '.7rem',
							},
							768: {
								perPage: 2,
								gap: '.7rem',
							},
							550: {
								perPage: 1,
								gap: '.7rem',
							},
						},
					}}>
					{partners?.map((partner, i) => (
						<SplideSlide key={i}>
							<div className={clsx(classPartner, s.Slide)}>
								{partner.link && (
									<Link className={s.Partner} name="Partner" link={partner.link}>
										<div className={s.Image}>
											{partner.logo?.url && partner.logo?.width && partner.logo?.height && (
												<Image
													alt=""
													src={partner.logo.url}
													width={partner.logo.width}
													height={partner.logo.height}
												/>
											)}
										</div>
									</Link>
								)}
							</div>
						</SplideSlide>
					))}
				</Splide>
			</div>
		</div>
	)
}
