import React from 'react'

export const ActiveUrlsContext = React.createContext<string[]>([])

export function useActivePage() {
	return React.useContext(ActiveUrlsContext)
}

export function useIsActiveUrl() {
	const activeUrls = useActivePage()

	return React.useCallback(
		(url: string) => {
			if (activeUrls.includes(url)) {
				return true
			}
			return false
		},
		[activeUrls]
	)
}
