import clsx from 'clsx'
import type { LinkResult } from '../data/content/LinkFragment'
import ArrowSvg from '../svg/sprites/Arrow'
import SvgArrowDownload from '../svg/sprites/ArrowDownload'
import s from './ArrowLink.module.sass'
import { Link } from './Link'

export type ArrowLinkProps = {
	text: string
	link?: LinkResult
	isDownload?: boolean
}

export function ArrowLink(props: ArrowLinkProps) {
	return (
		<>
			{props.link && (
				<Link className={s.ArrowLink} link={props.link}>
					<span className={clsx(s.Link, s.Text)}>{props.text}</span>
					<span className={clsx(s.Link, s.Arrow)}>
						{props.isDownload ? <SvgArrowDownload /> : <ArrowSvg />}
					</span>
				</Link>
			)}
		</>
	)
}
