import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import s from './BoxWithList.module.sass'

export function BoxWithList({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div
			id={`section-${reference.block?.order}`}
			className={clsx(s.BoxWithList, 'maybePaddingRight')}>
			{reference.boxesWithList.map((item, i) => (
				<div key={i} className={s.BoxContainer}>
					<div key={i} className={s.Box}>
						<h3 className={s.Title}>{item.primaryText}</h3>
						<ul className={s.List}>
							{item.textList?.items.map((item, i) => (
								<li className={s.BoxItem} key={i}>
									<RichTextRenderer source={item.text ?? null} />
								</li>
							))}
						</ul>
					</div>
				</div>
			))}
		</div>
	)
}
