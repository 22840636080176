import React from 'react'

export default function Breaklines(props: { source: string }) {
	const lines = props.source.split('\n')
	return (
		<>
			{lines.map((line, i) => {
				return (
					<React.Fragment key={i}>
						{line}
						{i < lines.length - 1 && <br />}
					</React.Fragment>
				)
			})}
		</>
	)
}
