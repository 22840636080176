import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import { CourseDetailEnum } from '../../../../generated/content'
import { usePageContext } from '../../../../pages/[[...page]]'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import { useTr } from '../../../utils/tr'
import s from './CourseDetail.module.sass'

export function CourseDetail({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	const page = usePageContext()
	const tr = useTr()
	const course = page.data.getLinkable?.course?.root

	if (!course) {
		return null
	}

	return (
		<div id={`section-${reference.block?.order}`} className={s.CourseDetail}>
			{reference.courseDetails.map((item, i) => {
				let content = <></>
				switch (item.courseDetail) {
					case CourseDetailEnum.dates:
						content = (
							<div className={s.Dates}>
								{course.dates.map((item, i) => (
									<React.Fragment key={i}>
										{i > 0 && <span className={s.Coma}>, </span>}
										{new Intl.DateTimeFormat('cs', {
											day: 'numeric',
											month: 'numeric',
											year: 'numeric',
										}).format(new Date(item.date))}
										{item.dateTo && ' - '}
										{item.dateTo &&
											new Intl.DateTimeFormat('cs', {
												day: 'numeric',
												month: 'numeric',
												year: 'numeric',
											}).format(new Date(item.dateTo))}
									</React.Fragment>
								))}
							</div>
						)
						break
					case CourseDetailEnum.time:
						content = (
							<>
								{course.time?.from && (
									<div className={s.InfoValue}>
										<p>{course.time?.from}</p>
										{course.time.to && <span className={s.Dash}>-</span>}
										<p>{course.time?.to}</p>
									</div>
								)}
								{course.time?.description && (
									<p className={s.InfoDescription}>{course.time?.description}</p>
								)}
							</>
						)
						break
					case CourseDetailEnum.prices:
						content = (
							<>
								{course.prices.map((item, i) => (
									<div className={s.Price} key={i}>
										<div className={s.InfoValue}>
											<p>{item.price}</p>
											{item.price && <p className={s.Currency}>{item.currency}</p>}
										</div>
										{item.description && <p className={s.InfoDescription}>{item.description}</p>}
									</div>
								))}
							</>
						)
						break
					case CourseDetailEnum.location:
						content = (
							<>
								{course.location && <p className={s.InfoValue}>{course.location?.location}</p>}
								{course.location?.description && (
									<p className={s.InfoDescription}>{course.location?.description}</p>
								)}
							</>
						)
						break
					case CourseDetailEnum.seats:
						content = (
							<>
								<p className={s.InfoValue}>{course.seats}</p>
							</>
						)
						break
					case CourseDetailEnum.custom:
						content = (
							<>
								<div key={i} className={s.CustomBox}>
									<p className={s.InfoValue}>{item.value}</p>
									<p className={s.InfoDescription}>{item.description}</p>
								</div>
							</>
						)
						break
				}
				if (item.courseDetail === CourseDetailEnum.custom) {
					return (
						<div key={i} className={s.InfoBox}>
							<p className={s.InfoLabel}>{item.headline}</p>
							{content}
						</div>
					)
				} else {
					return (
						<div key={i} className={s.InfoBox}>
							<p className={s.InfoLabel}>{tr(item.courseDetail)}</p>
							{content}
						</div>
					)
				}
			})}
		</div>
	)
}
