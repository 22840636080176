import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import * as FormComponent from '../../Form'
import s from './Form.module.sass'

export function Form({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.Form}>
			{reference.form && <FormComponent.Form form={reference.form} />}
		</div>
	)
}
