import type { ReferenceRendererProps } from '@contember/react-client'
import cn from 'clsx'
import Image from 'next/image'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import ArrowSvg from '../../../svg/sprites/Arrow'
import { Link } from '../../Link'
import s from './IconsWithHeadline.module.sass'

export function IconsWithHeadline({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.IconsWithHeadlineBox}>
			<div className={s.IconsWithHeadlineContainer}>
				<div className={s.IconsWithHeadline}>
					<div className={cn(s.Title, s.Box)}>{reference.primaryText}</div>
					{reference.items.map((item, i) => (
						<div className={s.Box} key={i}>
							<div className={s.Image}>
								{item.image?.url && item.image?.width && item.image?.height && (
									<Image
										alt=""
										src={item.image.url}
										width={item.image.width}
										height={item.image.height}
									/>
								)}
							</div>
							<p className={s.IconText}>{item.text}</p>
						</div>
					))}
				</div>
				{reference.link && (
					<div className={s.LinkBox}>
						{reference.link && <Link className={s.Link} link={reference.link} />}
						<ArrowSvg />
					</div>
				)}
			</div>
		</div>
	)
}
