import * as React from 'react'

const SvgPlayBtn = () => (
	<svg width={94} height={94} fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx={47} cy={47} r={47} fill="#FFB000" />
		<path fillRule="evenodd" clipRule="evenodd" d="M74 47 34 67V27l40 20Z" fill="currentColor" />
	</svg>
)

export default SvgPlayBtn
