import cn from 'clsx'
import Image from 'next/image'
import React, { useState } from 'react'
import i18n from '../../i18n'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { ActiveUrlsContext, useIsActiveUrl } from '../contexts/ActiveUrlsContext'
import SvgArrow from '../svg/sprites/Arrow'
import SvgHamburgerClose from '../svg/sprites/HamburgerClose'
import SvgHamburgerMobile from '../svg/sprites/HamburgerMobile'
import SvgHeart from '../svg/sprites/Heart'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import { usePageLoadingState } from '../utils/usePageLoadingState'
import { Breadcrumb, Breadcrumbs } from './Breadcrumbs'
import s from './Header.module.sass'
import { getLink, Link } from './Link'
export type HeaderProps = {
	header: NonNullable<CommonPageProps['data']['getHeaderMenuLocale']>
	homePage?: NonNullable<CommonPageProps['data']['getHomePageLocale']>
	translations: {
		id: string
		link?: {
			url: string
		}
		locale?: {
			code: string
		}
	}[]
	breadcrumbs?: Breadcrumb[]
}

export function Header(props: HeaderProps) {
	const [open, setOpen] = useState(false)
	const [loading] = usePageLoadingState()
	const isActive = useIsActiveUrl()

	React.useEffect(() => {
		setOpen(false)
	}, [loading])

	const homepageUrl = props.homePage?.link?.url ?? '/'

	const translations = props.translations.filter(
		(tr) => tr.locale?.code !== props.header.locale?.code
	)

	const existingLocales = props.translations.map((tr) => tr.locale?.code).filter(filterNonEmpty)

	const missingLocales = i18n.locales.filter(
		(locale) => !existingLocales.includes(locale) && locale !== props.header.locale?.code
	)

	const links = props.header.headerLinks?.items

	const activeLinks = React.useMemo(() => {
		const sortedLinks = links
			?.map((item) => item.link)
			.filter(filterNonEmpty)
			.map((link) => getLink(link))
			.map((link) => link.href)
			.sort((a, b) => b.length - a.length)
		const longestActiveUrl = sortedLinks?.find((href) => isActive(href))
		return [longestActiveUrl].filter(filterNonEmpty)
	}, [isActive, links])

	return (
		<>
			<ActiveUrlsContext.Provider value={activeLinks}>
				<div className={s.HeaderSticky}>
					<div className={s.HamburgerMobile}>
						<Link name="Logo" href={homepageUrl} className={s.ImageMobile} nonExternal={true}>
							{props.header.logoMobile?.url &&
								props.header.logoMobile?.width &&
								props.header.logoMobile?.height && (
									<Image
										alt=""
										src={props.header.logoMobile.url}
										width={props.header.logoMobile.width}
										height={props.header.logoMobile.height}
									/>
								)}
						</Link>
						<button
							name="Menu"
							title="Menu"
							className={s.HamburgerButton}
							onClick={() => setOpen((old) => !old)}>
							<SvgHamburgerMobile />
						</button>
					</div>
					<div className={cn(s.Header, open && s.isActive)}>
						<button
							className={cn(s.HamburgerButton, s.HamburgerClose)}
							onClick={() => setOpen((old) => !old)}>
							<SvgHamburgerClose />
						</button>
						<Link href={homepageUrl} className={s.Image} nonExternal>
							{props.header.logo?.url && props.header.logo?.width && props.header.logo?.height && (
								<Image
									alt=""
									src={props.header.logo.url}
									width={props.header.logo.width}
									height={props.header.logo.height}
								/>
							)}
						</Link>
						<div className={s.LinksBox}>
							<div className={s.Links}>
								<div className={s.LinkContainer}>
									{props.header.headerLinks?.items.map((item, i) => (
										<div className={cn(s.Link, s.LinkMobile)} key={i}>
											{item.link && <Link link={item.link} activeClassName={s.IsActiveLink} />}
										</div>
									))}
								</div>
								<div className={s.ButtonDesktop}>
									<div className={s.ButtonLink}>
										{props.header.button && (
											<Link link={props.header.button} activeClassName={s.isActive} />
										)}
										<SvgArrow />
									</div>
								</div>
								<div className={s.MobileDonateBox}>
									{props.header.button && (
										<Link link={props.header.button}>
											<div className={s.ButtonMobile}>
												<div className={s.ButtonDescriptionBox}>
													<p className={s.ButtonDescription}>{props.header.button?.description}</p>
													<SvgHeart />
												</div>
												<div className={s.ButtonLink}>
													{props.header.button.title}
													<SvgArrow />
												</div>
											</div>
										</Link>
									)}
								</div>
							</div>
							<div className={s.LanguageSwitcher}>
								<span className={cn(s.Link, s.Active)}>
									{props.header.locale?.code === 'cs' ? 'cz' : 'en'}
								</span>
								{translations.map((item, i) => (
									<div key={i}>
										<a
											className={s.Link}
											onClick={() => {
												document.cookie = `NEXT_LOCALE=${item.locale?.code}; path=/`
											}}
											href={item.link?.url}>
											{item.locale?.code === 'cs' ? 'cz' : 'en'}
										</a>
									</div>
								))}
								{missingLocales.map((locale, i) => (
									<div key={i}>
										<a
											className={s.Link}
											onClick={() => {
												document.cookie = `NEXT_LOCALE=${locale}; path=/`
											}}
											href={locale === i18n.defaultLocale ? '/' : `/${locale}`}>
											{locale === 'cs' ? 'cz' : 'en'}
										</a>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				{props.breadcrumbs && <Breadcrumbs breadcrumbs={props.breadcrumbs} />}
			</ActiveUrlsContext.Provider>
		</>
	)
}
