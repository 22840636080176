import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import s from './InfoBoxes.module.sass'

export function InfoBoxes({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div className={s.InfoBoxes} id={`section-${reference.block?.order}`}>
			{reference.infoBox.map((item, i) => (
				<div key={i} className={s.InfoBox}>
					<div className={s.Cover}>
						<div className={s.Image}></div>
						<p className={s.Title}>{item.title} </p>
					</div>
					<p className={s.Subtitle}>{item.subtitle} </p>
					<p className={s.Text}>{item.text} </p>
				</div>
			))}
		</div>
	)
}
