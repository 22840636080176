import * as React from 'react'

const SvgArrowDown = () => (
	<svg width={128} height={128} fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#ArrowDown_svg__a)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M64.717 116.122c1.11-2.588 2.404-4.622 3.329-6.656 9.985-27.367 10.91-54.548 1.849-82.47-2.22-7.026-5.363-13.498-9.43-19.785-.74-1.11-1.48-2.589-2.035-3.883-.37-1.11.37-2.219 1.48-2.219 1.11 0 2.034.185 2.588.74.555.554.74 1.479 1.11 2.219 1.479 3.328 2.958 7.026 4.438 10.354 5.732 12.39 9.43 25.333 10.54 39.016.554 7.212 1.109 14.053.37 21.08-.925 7.95-1.48 16.272-4.069 24.038l-3.883 12.759c1.48.739 1.85 0 2.219-.74 1.294-2.774 2.774-5.732 4.253-8.321 1.11-2.219 2.404-4.253 3.883-6.472.555-.924 1.85-1.11 2.774-.184.554.554.924 1.664.37 2.218-3.329 6.657-6.657 12.944-10.17 19.416-1.48 2.589-3.329 5.177-5.178 7.766-1.11 1.479-2.589 1.479-3.883.555a6.382 6.382 0 0 1-2.219-2.219c-.37-.37-.37-1.11-.74-1.849-2.219-4.808-4.437-9.246-6.656-14.053-.555-1.295 0-2.959.74-3.329 1.294-.554 3.143.185 3.697 1.48.37 1.109.555 2.403 1.11 3.698.74 2.219 1.11 4.438 3.513 6.841z"
				fill="#F9B000"
			/>
		</g>
		<defs>
			<clipPath id="ArrowDown_svg__a">
				<path fill="#fff" transform="rotate(135 49.876 59.762)" d="M0 0h85.772v94.402H0z" />
			</clipPath>
		</defs>
	</svg>
)

export default SvgArrowDown
