import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import s from './YoutubeVideo.module.sass'

export function YoutubeVideo({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div className={s.YoutubeVideo}>
			<iframe
				width="560"
				height="315"
				src={`https://www.youtube.com/embed/${reference.youtubeVideo?.videoId}`}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="YouTube Video"></iframe>
		</div>
	)
}
