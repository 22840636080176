import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import { useLecturers } from '../../../contexts/LecturersContext'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import { Lecturers } from '../../Lecturers'

export function CourseLecturers({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	const lecturers = useLecturers()
	return lecturers && <Lecturers lecturers={lecturers} title={reference.primaryText ?? ''} />
}
