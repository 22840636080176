import type { CommonPageProps } from '../../pages/[[...page]]'
import { BlockRenderer } from './blocks/BlockRenderer'
import { Cover } from './Cover'

export type topicProps = {
	topic: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['topic']>
}

export function Topic(props: CommonPageProps & topicProps) {
	return (
		<div>
			{props.topic.cover && <Cover cover={props.topic.cover} />}
			<BlockRenderer blocks={props.topic.content?.blocks ?? []} />
		</div>
	)
}
