import { useRouter } from 'next/dist/client/router'

const translations = {
	dates: {
		cs: 'Termín',
		en: 'Dates',
	},
	time: {
		cs: 'Čas',
		en: 'Time',
	},
	prices: {
		cs: 'Cena',
		en: 'Price',
	},
	location: {
		cs: 'Lokace',
		en: 'Location',
	},
	seats: {
		cs: 'Obsazení',
		en: 'Capacity',
	},
} as const

type Translations = typeof translations

export function tr<Key extends keyof Translations>(
	key: Key | string | undefined | null,
	locale: string
) {
	if (key) {
		if (key in translations) {
			const t = translations[key as keyof Translations]

			if (locale in t) {
				const value = t[locale as keyof typeof t]

				return value
			}
		}
	}

	return null
}

export function useTr() {
	const router = useRouter()
	const locale = router.locale ?? 'en'

	return <Key extends keyof Translations>(key: Key | string | undefined | null) => tr(key, locale)
}
