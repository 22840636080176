import type { CommonPageProps } from '../../pages/[[...page]]'
import { BlockRenderer } from './blocks/BlockRenderer'
import s from './CoursePage.module.sass'
import { CourseTile } from './CourseTile'
import { Cover } from './Cover'

export type CourseProps = {
	coursePage: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['coursePage']>
}

export function CoursePage(props: CommonPageProps & CourseProps) {
	return (
		<div className={s.CoursePage}>
			<div className={s.Cover}>
				{props.coursePage.cover && <Cover cover={props.coursePage.cover} />}
			</div>
			<div className={s.CoursesBox}>
				{props.coursePage.topics.map(
					(topic, id) =>
						topic.topic?.course && (
							<div key={id} className={s.CourseSection}>
								{topic.topic.course.length >= 1 && (
									<h3 className={s.TopicTitle}>
										{topic.topic.localesByLocale?.cover?.headlineTop}{' '}
										{topic.topic.localesByLocale?.cover?.headlineBottom}
									</h3>
								)}
								{topic.topic?.course.length >= 1 && (
									<div className={s.Courses}>
										{topic.topic.course.map((course, i) => (
											<>
												{course.localesByLocale?.published && (
													<a
														href={
															course.localesByLocale?.externalUrl ||
															course.localesByLocale?.link?.url
														}
														className={s.Course}
														key={i}>
														<CourseTile course={course} />
													</a>
												)}
											</>
										))}
									</div>
								)}
							</div>
						)
				)}
			</div>
			<div className={s.Content}>
				<BlockRenderer blocks={props.coursePage.content?.blocks ?? []} />
			</div>
		</div>
	)
}
