/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import type { ModalBannerResult } from '../data/content/ModalBannerFragment'
import SvgArrow from '../svg/sprites/Arrow'
import { ArrowLink } from './ArrowLink'
import s from './blocks/referenceRenderers/Banners.module.sass'
import { ContemberImage } from './ContemberImage'
import styles from './ModalBanner.module.sass'
import { ModalWrapper } from './ModalWrapper'
import { NewsletterForm } from './NewsletterForm'

export type ModalBannerProps = {
	modalBanner: ModalBannerResult
}

const MODAL_BANNER_NAME = 'modalBannerClosed'
const MODAL_BANNER_TIMEOUT_TIME = 60000 * 60 * 24 * 5

export const ModalBanner = (props: ModalBannerProps) => {
	const [close, setClose] = useState(false)

	useEffect(() => {
		const modalBannerTime = localStorage.getItem(MODAL_BANNER_NAME)

		if (modalBannerTime) {
			const item = JSON.parse(modalBannerTime)
			const now = new Date()
			if (now.getTime() > item) {
				setClose(false)
			} else {
				setClose(true)
			}
		}
	}, [])

	const setExpiryModalBanner = useCallback((ttl: number) => {
		const now = new Date()
		localStorage.setItem(MODAL_BANNER_NAME, JSON.stringify(now.getTime() + ttl))
	}, [])

	const [isOpen, setIsOpen] = React.useState(false)

	return (
		<div className={clsx(styles.container, close && styles.close)}>
			<div className={clsx(styles.wrapper, close && styles.close)}>
				<div className={styles.wrapperIn}>
					<div className={styles.title}>{props.modalBanner?.localesByLocale?.title}</div>
					{props.modalBanner?.localesByLocale?.text && (
						<div className={styles.text}>
							<RichTextRenderer source={props.modalBanner?.localesByLocale?.text} />
						</div>
					)}
					{props.modalBanner.localesByLocale?.newsletterButtonLabel && (
						<button
							className={clsx(styles.newsletterLink, 'gtm_click2modal')}
							onClick={() => {
								setIsOpen((open) => {
									return !open
								})
								setClose(true)
							}}>
							{props.modalBanner.localesByLocale?.newsletterButtonLabel}
							<span className={styles.arrow}>
								<SvgArrow />
							</span>
						</button>
					)}

					{props.modalBanner.localesByLocale?.link?.title && (
						<div className={styles.link}>
							<ArrowLink
								link={props.modalBanner.localesByLocale?.link}
								text={props.modalBanner.localesByLocale?.link?.title ?? ''}
							/>
						</div>
					)}
					{props.modalBanner.localesByLocale?.donationEmbed && (
						<iframe
							width="100%"
							height={props.modalBanner.localesByLocale.heightOfEmbed ?? '251'}
							src={props.modalBanner.localesByLocale?.donationEmbed}
							scrolling="no"
							className={styles.iFrame}
							title="iFrame"
						/>
					)}
				</div>
				{props.modalBanner.localesByLocale?.image && (
					<div className={styles.image}>
						<ContemberImage image={props.modalBanner.localesByLocale?.image} />
					</div>
				)}
				<div
					className={styles.crossButton}
					role="button"
					onClick={() => {
						setClose(true)
						setExpiryModalBanner(MODAL_BANNER_TIMEOUT_TIME)
					}}>
					<div className={styles.line1}></div>
					<div className={styles.line2}></div>
				</div>
				{props.modalBanner.localesByLocale?.newsletter && (
					<ModalWrapper isOpen={isOpen} closeModal={() => setIsOpen(false)}>
						<div className={s.NewsletterForm}>
							<div className={s.NewsletterFormContainer}>
								<div className={s.NewsletterFormTextBox}>
									{props.modalBanner.localesByLocale?.newsletter && (
										<NewsletterForm
											redirectToSalesforce
											newsletter={props.modalBanner.localesByLocale?.newsletter}
										/>
									)}
								</div>
							</div>
						</div>
					</ModalWrapper>
				)}
			</div>
		</div>
	)
}
