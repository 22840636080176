import type { ReferenceRendererProps } from '@contember/react-client'
import cn from 'clsx'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import { Heart } from '../../../svg/sprites'
import { ArrowLink } from '../../ArrowLink'
import { ContemberVideo } from '../../ContemberVideo'
import s from './Video.module.sass'

export function Video({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div
			id={`section-${reference.block?.order}`}
			className={cn(s.VideoBox, reference.primaryText && s.VideoBoxBig)}>
			<div className={s.Video}>
				{reference.video?.src && <ContemberVideo video={reference.video} forcePosterBeforePlay />}
			</div>

			{reference.primaryText && (
				<div className={s.TextBox}>
					<div className={s.TitleBox}>
						<h5 className={s.Title}>{reference.primaryText}</h5>
						<span className={s.Heart}>
							<Heart />
						</span>
					</div>
					<div>
						<ArrowLink link={reference.link} text={reference.link?.title ?? ''} />
					</div>
				</div>
			)}
		</div>
	)
}
