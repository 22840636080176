import React from 'react'
import type { LecturerResult } from '../data/content/LecturerFragment'

export const LecturersContext = React.createContext<null | LecturerResult[]>(null)

export function useLecturers() {
	const context = React.useContext(LecturersContext)

	return context
}
