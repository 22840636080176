import React from 'react'
import s from './Breadcrumbs.module.sass'
import { Link } from './Link'

export type BreadcrumbItem = {
	url?: string | null
	title: string
}

export type Breadcrumb = BreadcrumbItem | BreadcrumbItem[]

export type BreadcrumbsProps = {
	breadcrumbs: Breadcrumb[]
}

export function Breadcrumbs(props: BreadcrumbsProps) {
	const breadcrumbs: Breadcrumb[] = []

	for (const b of props.breadcrumbs) {
		if (Array.isArray(b)) {
			if (b.length > 0) {
				breadcrumbs.push(b)
			}
		} else {
			breadcrumbs.push(b)
		}
	}

	return (
		<>
			{breadcrumbs.length > 0 && (
				<div className={s.Breadcrumbs}>
					{breadcrumbs.map((breadcrumb, i) => {
						const parts = Array.isArray(breadcrumb) ? breadcrumb : [breadcrumb]
						return (
							<React.Fragment key={i}>
								{i !== 0 && <span className={s.Dash}>—</span>}
								{parts.map((part, j) => (
									<React.Fragment key={j}>
										{j !== 0 && <span className={s.Comma}>, </span>}
										{part.url ? (
											<Link className={s.Breadcrumb} href={part.url} nonExternal>
												{part.title}
											</Link>
										) : (
											<span className={s.Breadcrumb}>{part.title}</span>
										)}
									</React.Fragment>
								))}
							</React.Fragment>
						)
					})}
				</div>
			)}
		</>
	)
}
