import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import SvgArrowBig from '../../../svg/sprites/ArrowBig'
import { Link } from '../../Link'
import s from './Button.module.sass'

export function Button({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.Wrapper}>
			<div className={s.Button}>
				{reference.link && (
					<Link link={reference.link} className={s.LinkBox}>
						<span className={s.Link}>{reference.link?.title}</span>
						<span>
							<SvgArrowBig />
						</span>
					</Link>
				)}
			</div>
		</div>
	)
}
