import Image from 'next/image'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { BlockRenderer } from './blocks/BlockRenderer'
import { Cover } from './Cover'
import s from './HomePage.module.sass'

export type HomeProps = {
	homePage: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['homePage']>
}

export function HomePage(props: CommonPageProps & HomeProps) {
	return (
		<div>
			<div className={s.AccentBox}>
				<div className={s.Accent}>
					{props.homePage.accentFirst?.image?.url && (
						<Image
							alt=""
							src={props.homePage.accentFirst.image.url}
							objectFit="contain"
							layout="fill"
						/>
					)}
				</div>
				<div className={s.Accent}>
					{props.homePage.accentSecond?.image?.url && (
						<Image
							alt=""
							src={props.homePage.accentSecond.image.url}
							objectFit="contain"
							layout="fill"
						/>
					)}
				</div>
			</div>
			{props.homePage.cover && <Cover cover={props.homePage.cover} />}
			<BlockRenderer blocks={props.homePage.content?.blocks ?? []} />
		</div>
	)
}
