import React from 'react'
import Modal from 'react-modal'
import SvgCloseBtn from '../svg/sprites/CloseBtn'
import s from './Modal.module.sass'

export interface ModalWrapperProps {
	children: React.ReactChild
	isOpen: boolean
	closeModal: () => void
}

export function ModalWrapper(props: ModalWrapperProps) {
	return (
		<Modal
			isOpen={props.isOpen}
			onRequestClose={() => props.closeModal()}
			ariaHideApp={false}
			overlayClassName={s.overlay}
			className={s.content}>
			<button
				type="button"
				className={s.close}
				onClick={() => {
					props.closeModal()
				}}>
				<SvgCloseBtn />
			</button>
			<div className={s.contentIn}>{props.children}</div>
		</Modal>
	)
}
