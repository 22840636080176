import clsx from 'clsx'
import Image from 'next/image'
import type { CourseResult } from '../data/content/CourseFragment'
import s from './CourseTile.module.sass'

export type CourseTileProps = {
	course: CourseResult
}

export function CourseTile(props: CourseTileProps) {
	const originalDates = props.course?.dates ?? []
	const dates = [...originalDates].slice(0, 2)
	const originalLecturers = props.course?.lecturers ?? []
	const lecturers = [...originalLecturers].slice(0, 2)
	const tileImage = props.course.tileImage ?? props.course.coverImage

	return (
		<div className={s.CourseContainer}>
			<div className={s.CourseBox}>
				<div className={s.TileImage}>
					{tileImage && <Image alt="" src={tileImage.url} layout="fill" objectFit="cover" />}
				</div>
				<div className={s.TextBox}>
					<div className={s.CourseBoxTop}>
						<div className={s.LecturerImageBox}>
							{lecturers.map((item, i) => (
								<div key={i} className={s.LecturerImage}>
									{item.lecturer?.image?.url && (
										<Image alt="" src={item.lecturer.image.url} layout="fill" objectFit="cover" />
									)}
								</div>
							))}
						</div>
						<div className={s.Dates}>
							{dates.map((item, i) => (
								<div key={i} className={s.Date}>
									<div className={s.DateBox}>
										<p>
											{new Intl.DateTimeFormat('cs', {
												day: 'numeric',
												month: 'numeric',
											}).format(new Date(item.date))}
										</p>
									</div>
								</div>
							))}

							{originalDates.length - dates.length >= 1 && (
								<div className={clsx(s.Date, s.PlusDateBox)}>
									<p className={s.PlusDates}>+{originalDates.length - dates.length}</p>
								</div>
							)}
						</div>
					</div>
					<div className={s.CourseTitleContainer}>
						<p className={s.CourseDescription}>{props.course.localesByLocale?.description}</p>
						<p className={s.CourseTitleBox}>
							<span className={s.CourseTitle}>{props.course?.localesByLocale?.title}</span>
							{props.course.localesByLocale?.titleBottom && <span className={s.Dash}>—</span>}
							<span className={s.CourseTitle}>{props.course?.localesByLocale?.titleBottom}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
