import * as React from 'react'

const SvgArrowBig = () => (
	<svg width={56} height={87} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="m35.683 41-1.898 1.781 4.118 3.89H27v2.514h10.903l-4.118 3.89 1.898 1.781L43 47.928 35.683 41z"
			fill="#1A1A1A"
		/>
		<g clipPath="url(#ArrowBig_svg__a)" fill="#F7F7F7">
			<path d="M39.268 22.66c-3.623-.62 6.407-9.422 6.253-15.372-.142-3.753 3.836-1.273 3.623.619-.616 4.69-3.424 15.714-9.876 14.752zm-7.216-3.813c-.77 1.43-1.35 1.952-2.065-.18-1.278-3.693-3.84-14.868-.394-13.835 5.108 1.322 4.437 9.572 2.459 14.015zm-5.209 6.903c-.348 1.928-1.545 1.76-2.327.991-1.717.582-6.87-11.122-4.469-12.376 3.012-1.663 7.016 7.903 6.796 11.386zm-12.385.014c-1.325.967-14.249-6.943-11.866-8.805 2.95-1.89 16.326 6.632 11.866 8.805z" />
		</g>
		<g clipPath="url(#ArrowBig_svg__b)" fill="#F7F7F7">
			<path d="M5 85.546c.194-.546 1.067-.819 1.745-1.091a77.83 77.83 0 0 0 9.211 0c2.036-.091 3.976-.364 5.915-.455 1.648-.09 3.2 0 4.75-.09 1.261-.092 2.522-.546 3.879-.274.776-.272 1.551-.181 2.424-.272 2.327-.273 4.557-.546 7.078-.819 3.103-.363 5.817-.363 8.823-.909 2.327-.363 4.46-.909 6.69 0 .097.364.388.728.388 1.182-1.745.91-3.975.637-5.914.91-2.036.272-4.073.454-6.109.727-4.072.454-8.241.727-12.314 1.09-8.24.728-16.773 1.182-25.209 1.637-.678-.546-.97-1.091-1.357-1.636zm40.723-9.819c-8.145.364-16.29 2.182-24.337 3.091-2.327.273-5.139.818-7.563 1.182-.872.182-1.551.364-2.327.546a4.327 4.327 0 0 1-3.005-.273c-.679-.819-.194-1.637.581-2.091C18.768 77.454 29.434 75 39.808 74c3.103-.273 6.108-.91 9.114-1 .776 0 1.552.182 2.23.364 2.521 2.363-3.684 1.818-5.43 2.363z" />
		</g>
		<defs>
			<clipPath id="ArrowBig_svg__a">
				<path fill="#fff" transform="rotate(165 24.953 13.5)" d="M0 0h48.004v20.789H0z" />
			</clipPath>
			<clipPath id="ArrowBig_svg__b">
				<path fill="#fff" transform="translate(5 73)" d="M0 0h51v14H0z" />
			</clipPath>
		</defs>
	</svg>
)

export default SvgArrowBig
