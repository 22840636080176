import Image from 'next/image'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { BlockRenderer } from './blocks/BlockRenderer'
import s from './Blog.module.sass'
import { Cover } from './Cover'
import { Share } from './Share'

export type BlogProps = {
	blog: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['blog']>
}

export function Blog(props: CommonPageProps & BlogProps) {
	return (
		<div>
			{props.blog.published && (
				<>
					<div className={s.Cover}>
						<div className={s.AccentBox}>
							<div className={s.Accent}>
								{props.blog.accentFirst?.image?.url && (
									<Image
										alt=""
										src={props.blog.accentFirst.image.url}
										objectFit="contain"
										layout="fill"
									/>
								)}
							</div>
							<div className={s.Accent}>
								{props.blog.accentSecond?.image?.url && (
									<Image
										alt=""
										src={props.blog.accentSecond.image.url}
										objectFit="contain"
										layout="fill"
									/>
								)}
							</div>
							<div className={s.Accent}>
								{props.blog.accentThird?.image?.url && (
									<Image
										alt=""
										src={props.blog.accentThird.image.url}
										objectFit="contain"
										layout="fill"
									/>
								)}
							</div>
						</div>
						{props.blog.cover && (
							<Cover isBlog={true} coverImageRightPosition={true} cover={props.blog.cover} />
						)}
					</div>

					<div className={s.DateShareBox}>
						<p className={s.CreatedAt}>
							{new Intl.DateTimeFormat('cs', {
								day: 'numeric',
								month: 'numeric',
								year: 'numeric',
							}).format(new Date(props.blog.root?.createdAt))}
						</p>
					</div>
					<div className={s.Content}>
						<div className={s.ShareBtnBox}>
							<span className={s.ShareBtn}>
								<Share />
							</span>
						</div>
						<div className={s.LeadParagraphBox}>
							<h3 className={s.LeadParagraph}>{props.blog.cover?.perex}</h3>
						</div>
						<BlockRenderer blocks={props.blog.content?.blocks ?? []} />
						<Share
							title={props.blog.shareHeadline}
							shareTitle={props.blog.cover?.headlineTop + ' ' + props.blog.cover?.headlineBottom}
						/>
					</div>
				</>
			)}
		</div>
	)
}
