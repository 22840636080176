import type { ReferenceRendererProps } from '@contember/react-client'
import { RichTextRenderer } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import s from './ArticleCredentials.module.sass'

export function ArticleCredentials({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.ArticleCredentials}>
			<p className={s.Text}>
				<RichTextRenderer source={reference.primaryText ?? null} />
			</p>
		</div>
	)
}
