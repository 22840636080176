import * as React from 'react'

const SvgHamburgerClose = () => (
	<svg width={46} height={46} fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx={23} cy={23} r={23} fill="#FFB000" />
		<path
			stroke="#1A1A1A"
			strokeWidth={3}
			d="m14.061 13.939 19.092 19.092m-19.26-.546 19.092-19.092"
		/>
	</svg>
)

export default SvgHamburgerClose
