import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import s from './Gallery.module.sass'

export function Gallery({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={clsx(s.Gallery, 'maybePaddingRight')}>
			{reference.gallery.map((item) => (
				<div key={item.id} className={s.ImageBox}>
					<div className={s.Image}>
						{item.image?.url && (
							<Image alt="" src={item.image.url} layout="fill" objectFit="cover" />
						)}
					</div>
				</div>
			))}
		</div>
	)
}
