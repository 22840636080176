import clsx from 'clsx'
import NextLink from 'next/link'
import React from 'react'
import { LinkType } from '../../generated/content'
import { useIsActiveUrl } from '../contexts/ActiveUrlsContext'
import type { LinkResult } from '../data/content/LinkFragment'

type LinkProps = {
	className?: string
	activeClassName?: string
	gradient?: boolean
	down?: boolean
	nonExternal?: boolean
	children?: React.ReactNode
	name?: string
} & ({ link: LinkResult } | { href: string })

export function getLink(link: LinkResult | string) {
	if (typeof link === 'string') {
		return { href: link, external: true, type: LinkType.external, title: null }
	} else {
		const external = link.type === LinkType.external

		const internalUrl = link.internalLink?.url
		const externalUrl = link.externalLink
		const anchorUrl = link.anchorLink

		const href = ((link.type === LinkType.external && externalUrl) ||
			(link.type === LinkType.download && externalUrl) ||
			(link.type === LinkType.internal && internalUrl) ||
			(link.type === LinkType.anchor && anchorUrl)) as string

		return { href, external, type: link.type, title: link.title }
	}
}

export function Link(props: LinkProps) {
	const isActiveUrl = useIsActiveUrl()

	const link = 'href' in props ? props.href : props.link
	const linkInfo = React.useMemo(() => getLink(link), [link])
	const isActive = isActiveUrl(linkInfo.href)

	if (linkInfo.href) {
		return (
			<NextLink href={linkInfo.href}>
				<a
					className={clsx(props.className, isActive && props.activeClassName)}
					target={linkInfo.external && !props.nonExternal ? '_blank' : ''}
					download={linkInfo.type === LinkType.download}
					title={props.name}>
					{props.children ?? linkInfo.title}
				</a>
			</NextLink>
		)
	} else {
		return null
	}
}
