import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import s from './Lecturers.module.sass'

export function Lecturers({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div
			id={`section-${reference.block?.order}`}
			className={clsx(s.LecturersBox, 'maybePaddingRight')}>
			<h3 className={s.Title}>{reference.primaryText}</h3>
			<div className={s.Lecturers}>
				{reference.lecturers.map((lecturer) => (
					<div className={s.Lecturer} key={lecturer.lecturer?.id}>
						<div className={s.Image}>
							{lecturer.lecturer?.image?.url &&
								lecturer.lecturer.image?.width &&
								lecturer.lecturer.image?.height && (
									<Image
										alt=""
										src={lecturer.lecturer.image.url}
										width={lecturer.lecturer.image.width}
										height={lecturer.lecturer.image.height}
									/>
								)}
						</div>
						<p className={s.Name}>
							<span className={s.FirstName}>{lecturer.lecturer?.firstName}</span>
							{lecturer.lecturer?.lastName && <span className={s.Dash}>—</span>}
							<span className={s.LastName}>{lecturer.lecturer?.lastName}</span>
						</p>
						<p className={s.Text}>{lecturer.lecturer?.localesByLocale?.position}</p>
						<a href={`mailto:${lecturer.lecturer?.email}`} className={s.Text}>
							{lecturer.lecturer?.email}
						</a>
					</div>
				))}
			</div>
		</div>
	)
}

export function LecturersWithDetails({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div
			id={`section-${reference.block?.order}`}
			className={clsx(s.LecturersBox, 'maybePaddingRight')}>
			<h3 className={s.Title}>{reference.primaryText}</h3>
			<div className={s.Lecturers}>
				{reference.lecturers.map((lecturer) => (
					<div className={clsx(s.Lecturer, s.LecturerWithDetail)} key={lecturer.lecturer?.id}>
						<div className={s.LecturerLeftBox}>
							<div className={s.Image}>
								{lecturer.lecturer?.image?.url &&
									lecturer.lecturer.image?.width &&
									lecturer.lecturer.image?.height && (
										<Image
											alt=""
											src={lecturer.lecturer.image.url}
											width={lecturer.lecturer.image.width}
											height={lecturer.lecturer.image.height}
										/>
									)}
							</div>
							<p className={s.Name}>
								<span className={s.FirstName}>{lecturer.lecturer?.firstName}</span>
								{lecturer.lecturer?.lastName && <span className={s.Dash}>—</span>}
								<span className={s.LastName}>{lecturer.lecturer?.lastName}</span>
							</p>
							<p className={s.Text}>{lecturer.lecturer?.localesByLocale?.position}</p>
							<p className={s.Text}>{lecturer.lecturer?.email}</p>
						</div>
						<div className={s.LecturerDetailText}>
							<RichTextRenderer source={lecturer.lecturer?.localesByLocale?.description ?? null} />
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
