import { RichTextRenderer } from '@contember/react-client'
import { default as clsx, default as cn } from 'clsx'
import Image from 'next/image'
import React from 'react'
import type { LecturerResult } from '../data/content/LecturerFragment'
import s from './Lecturers.module.sass'

type LecturersProps = {
	lecturers: LecturerResult[]
	title: string
}
export function Lecturers(props: LecturersProps) {
	return (
		<div className={clsx(s.LecturersBox, 'maybePaddingRight')}>
			<h3 className={s.Title}>{props.title}</h3>
			{props.lecturers.map((item, i) => (
				<div key={i} className={s.Lecturer}>
					<div className={s.LecturerInfo}>
						<div className={s.Image}>
							{item.image?.url && (
								<Image alt="" src={item.image.url} objectFit="cover" layout="fill" />
							)}
						</div>
						<p className={s.Name}>{item.firstName}</p>
						{item.lastName && <span className={cn(s.Name, s.Dash)}>—</span>}
						<p className={s.Name}>{item.lastName}</p>
						<p>{item.localesByLocale?.position}</p>
						<p>{item.email}</p>
					</div>
					<div className={s.Text}>
						<RichTextRenderer source={item.localesByLocale?.description ?? null} />
					</div>
				</div>
			))}
		</div>
	)
}
