import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { FormEvent, useEffect, useState } from 'react'
import { FormFieldType } from '../../generated/content'
import { LocaleContext } from '../contexts/LocaleContext'
import type { FormResult } from '../data/content/FormFragment'
import SvgArrow from '../svg/sprites/Arrow'
import { sendDataLayerFormSent } from '../utils/sendDataLayerFormSent'
import s from './Form.module.sass'

export type FormProps = {
	title?: React.ReactNode
	form: FormResult
	submitEnabled?: boolean
	errorMessages?: { [key: string]: string }
	handleSubmit?: (event: FormEvent) => void
	formFieldsHidden?: boolean
	redirectToSalesforce?: boolean
}

export function Form(props: FormProps) {
	const router = useRouter()
	const isSuccess = props.form.id === router.query.success

	const [returnUrl, setReturnUrl] = useState('')

	const errorMessages = Object.entries(props.errorMessages || {}).map(([_key, value]) => {
		return value
	})

	useEffect(() => {
		setReturnUrl(`${window.location.href}?success=${props.form.id}#SuccessBoxAnchor`)
	}, [props.form.id])

	const form = props.form

	const handleSubmit =
		props.handleSubmit ||
		function (event: FormEvent) {
			event?.preventDefault()

			if (event.currentTarget instanceof HTMLFormElement) {
				event.currentTarget.submit()
			}
		}

	useEffect(() => {
		if (isSuccess) {
			sendDataLayerFormSent(form.gaDatalayerIdentifier ?? 'nezarazenyFormular')
		}
	}, [isSuccess, form.gaDatalayerIdentifier])

	return (
		<>
			<form
				id="form"
				className={s.Form}
				action={
					props.redirectToSalesforce
						? 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
						: returnUrl
				}
				method="POST"
				onSubmit={(event) => handleSubmit(event)}>
				{isSuccess ? (
					<>
						<div className={s.SuccessBoxAnchor} id="SuccessBoxAnchor"></div>
						<div className={s.SuccessBox}>
							<h2 className={s.SuccessTitle}>{props.form.localesByLocale?.successMessageTitle}</h2>
							<RichTextRenderer source={props.form.localesByLocale?.successMessage ?? null} />
						</div>
					</>
				) : (
					<div className={clsx(s.Fields, props.formFieldsHidden && s.FormFieldsHidden)}>
						<h3 className={s.title}>{props.title}</h3>

						<input type="hidden" name="retURL" value={returnUrl} />
						{/* <input
							type="hidden"
							name="captcha_settings"
							value={`{"keyname":"AperioRecaptcha","fallback":"true","orgId":"00D0900000B5Inf","ts":""}`}
						/> */}
						<input type="hidden" name="oid" value="00D0900000B5Inf" />
						{form?.fields.map((fieldItem) => {
							const field = fieldItem.field
							return (
								field && (
									<div
										key={field.id}
										className={clsx(s.Field, field.type === FormFieldType.hidden && s.hidden)}>
										{field.type === FormFieldType.hidden && (
											<input type={field.type} name={field.name} value={field.value} />
										)}
										{(field.type === FormFieldType.text ||
											field.type === FormFieldType.email ||
											field.type === FormFieldType.tel) && (
											<label>
												{field.localesByLocale?.label}
												<input
													type={field.type}
													name={field.name}
													autoComplete={field.autocomplete}
													required={field.required}
													maxLength={field.maxlength ? parseInt(field.maxlength, 10) : undefined}
													pattern={field.pattern}
													onInvalid={
														field.type === FormFieldType.tel
															? (e) =>
																	e.currentTarget.setCustomValidity('Zadejte ve formátu +420...')
															: undefined
													}
													onInput={
														field.type === FormFieldType.tel
															? (e) => e.currentTarget.setCustomValidity('')
															: undefined
													}
												/>
											</label>
										)}
										{field.type === FormFieldType.textarea && (
											<label>
												{field.localesByLocale?.label}
												<textarea
													name={field.name}
													autoComplete={field.autocomplete}
													required={field.required}
													maxLength={field.maxlength ? parseInt(field.maxlength, 10) : undefined}
												/>
											</label>
										)}
										{field.type === FormFieldType.checkbox && (
											<div className={s.NewsletterRadioBox}>
												<label className={s.RadioLabel}>
													<input
														name={field.name}
														type="checkbox"
														className={s.GdprBtn}
														value={field.value}
														required
													/>
													{field.localesByLocale?.label}
												</label>
											</div>
										)}
										{field.type === FormFieldType.select && (
											<>
												<label>
													{field.localesByLocale?.label}
													<select
														className={s.Select}
														name={field.name}
														required={field.required}
														defaultValue={''}>
														<option value="" disabled>
															{LocaleContext ? 'Vyberte' : 'Choose'}
														</option>
														{field.options.map((option) => (
															<React.Fragment key={option.id}>
																<option value={option.value}>
																	{option.localesByLocale?.label}
																</option>
															</React.Fragment>
														))}
													</select>
												</label>
											</>
										)}
										{field.localesByLocale?.note && (
											<p className={s.ItemNote}>
												<span className={s.LabelCharacter}>* </span>
												<RichTextRenderer source={field.localesByLocale.note} />
											</p>
										)}
									</div>
								)
							)
						})}
						{/* <div
							className="g-recaptcha"
							data-sitekey="6Lcr_lodAAAAAPsKLIT5hcv1XnLHLSp7w9gkkIpD"></div> */}
						{errorMessages.map((errorMessage, index) => (
							<div key={`error-${index}`} className={s.errorMessage}>
								{errorMessage}
							</div>
						))}
						<div
							className={clsx(
								s.SubmitBox,
								props.submitEnabled !== undefined && !props.submitEnabled ? s.disabled : undefined
							)}>
							<button
								className={s.Submit}
								disabled={props.submitEnabled !== undefined && !props.submitEnabled}
								type="submit">
								Odeslat
							</button>
							<SvgArrow />
						</div>
					</div>
				)}
			</form>
		</>
	)
}
