import type { ReferenceRendererProps } from '@contember/react-client'
import { default as clsx, default as cn } from 'clsx'
import Image from 'next/image'
import React, { useState } from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import ArrowSvg from '../../../svg/sprites/Arrow'
import s from './Reference.module.sass'

export function Reference({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	const [isExpanded, setIsExpanded] = useState(false)
	const visibleReferences = isExpanded ? reference.references : reference.references.slice(0, 2)
	return (
		<div id={`section-${reference.block?.order}`} className={s.ReferencesBox}>
			<div className={s.References}>
				<h3 className={s.Title}>{reference.primaryText}</h3>
				{visibleReferences.map((item, i) => (
					<div key={i} className={s.Reference}>
						<div className={s.PersonBox}>
							<div className={s.Image}>
								{item.author?.image?.url &&
									item.author.image?.width &&
									item.author.image?.height && (
										<Image
											alt=""
											src={item.author.image.url}
											width={item.author.image.width}
											height={item.author.image.height}
										/>
									)}
							</div>
							<div>
								<p className={s.Name}>{item.author?.firstName}</p>
								{item.author?.lastName && <span className={cn(s.Name, s.Dash)}>—</span>}
								<p className={s.Name}>{item.author?.lastName}</p>
								<p className={s.Position}>{item.author?.position}</p>
							</div>
						</div>
						<h3 className={s.Text}>
							<span className={s.QuotationMarks}>„</span>
							{item.text}
							<span className={s.QuotationMarks}>“</span>
						</h3>
					</div>
				))}
				{!isExpanded && reference.references.length > 2 && (
					<div className={clsx(s.ExpandedBtnWrapper, isExpanded && s.isExpanded)}>
						<button className={s.ExpandedBtn} onClick={() => setIsExpanded(true)}>
							{reference.secondaryText || 'Další'}
						</button>
						<span className={s.Arrow}>
							<ArrowSvg />
						</span>
					</div>
				)}
			</div>
		</div>
	)
}
