import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import 'collapsible-react-component/dist/index.css'
import Link from 'next/link'
import { useState } from 'react'
import SvgMinus from '../svg/sprites/Minus'
import SvgPlus from '../svg/sprites/Plus'
import s from './QuestionAnswer.module.sass'

export type QuestionAnswerProps = {
	answer: string
	category: string
	date: string
	id: number
	question: string
	title: string
	baseUrl: string
}

export function QuestionAnswer(props: QuestionAnswerProps) {
	const [isAnswerVisible, setIsAnswerVisible] = useState(false)

	const questionLines = props.question.split(/\r?\n/)
	const answerLines = props.answer.split(/\r?\n/)

	return (
		<div className={s.QuestionAnswer}>
			<div className={s.QuestionWrapper}>
				<Link href={`${props.baseUrl}?question=${props.id}`}>
					<a className={s.TitleWrapper}>
						<div>
							<p className={s.LabelTitle}>Téma:</p>
							<h3 className={s.Title}>{props.title}</h3>
						</div>
						<p className={s.Date}>{new Date(props.date).toLocaleDateString('cs')}</p>
					</a>
				</Link>
				<div className={s.Question}>
					{questionLines.map((line, index) => (
						<p key={`question-line-${props.id}-${index}`}>{line}</p>
					))}
					<p className={s.CategoryType}>
						Kategorie dotazu:{' '}
						<Link href={`${props.baseUrl}?category=${encodeURIComponent(props.category)}`}>
							<a className={s.CategoryTypeLink}>{props.category}</a>
						</Link>
					</p>
				</div>
				<button
					className={clsx(s.AnswerBox, isAnswerVisible && s.Visible)}
					type="button"
					onClick={() => setIsAnswerVisible(!isAnswerVisible)}>
					{!isAnswerVisible && (
						<span className={s.AnswerBtn}>
							<p>Zobrazit odpověď</p>
							<SvgPlus />
						</span>
					)}
					{isAnswerVisible && (
						<span className={s.AnswerBtn}>
							<p>Schovat odpověď</p>
							<SvgMinus />
						</span>
					)}
				</button>
			</div>
			<Collapsible open={isAnswerVisible}>
				<div className={s.Answer}>
					{answerLines.map((line, index) => (
						<p key={`answer-line-${props.id}-${index}`}>{line}</p>
					))}
					<p className={s.CategoryType}>
						Kategorie dotazu:{' '}
						<Link href={`?category=${encodeURIComponent(props.category)}`}>
							<a className={s.CategoryTypeLink}>{props.category}</a>
						</Link>
					</p>
				</div>
			</Collapsible>
		</div>
	)
}
