import type { CommonPageProps } from '../../pages/[[...page]]'
import { BlockRenderer } from './blocks/BlockRenderer'

export type PageProps = {
	page: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['page']>
}

export function Page(props: PageProps) {
	return (
		<div>
			<BlockRenderer blocks={props.page.content?.blocks ?? []} />
		</div>
	)
}
