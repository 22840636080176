import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { BlockRenderer } from './blocks/BlockRenderer'
import s from './CounselingPage.module.sass'
import { NewsletterForm } from './NewsletterForm'
import { Pagination } from './Pagination'
import { QuestionAnswer } from './QuestionAnswer'

interface QuestionData {
	answer: string
	category: string
	date: string
	id: number
	question: string
	title: string
}

export type CounselingProps = {
	counselingPage: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['counselingPage']>
}

export function CounselingPage(props: CommonPageProps & CounselingProps) {
	const router = useRouter()

	const isContentVisible = !router.query.category && !router.query.question

	const [categories, setCategories] = useState<string[]>([])
	const [questions, setQuestions] = useState<QuestionData[]>([])
	const [maxPage, setMaxPage] = useState(1)
	const [questionsLimit, setQuestionsLimit] = useState(0)
	const [questionsTaken, setQuestionsTaken] = useState(0)

	useEffect(() => {
		;(async () => {
			const response = await fetch(`/api/poradna/categories`, {
				method: 'GET',
			})
			const data = await response.json()
			setMaxPage(data.pages)
			setCategories(data.data)
		})()
	}, [])

	useEffect(() => {
		;(async () => {
			setMaxPage(1)
			if (router.query.category) {
				const response = await fetch(
					`/api/poradna/categories/${router.query.category}${
						router.query.p ? '?p=' + router.query.p : ''
					}`,
					{
						method: 'GET',
					}
				)
				const data = await response.json()
				setMaxPage(data.meta.pages)
				setQuestions(data.data)
			} else if (router.query.question) {
				const response = await fetch(`/api/poradna/questions/${router.query.question}`, {
					method: 'GET',
				})
				const data = await response.json()
				setQuestions([data.data])
			} else {
				const response = await fetch('/api/poradna/questions', {
					method: 'GET',
				})
				const data = await response.json()
				setQuestions(data.data)

				const limitResponse = await fetch('/api/poradna/limit', {
					method: 'GET',
				})
				const limitData = await limitResponse.json()
				setQuestionsLimit(limitData.data.limit)
				setQuestionsTaken(limitData.data.taken)
			}
		})()
	}, [router.query])

	return (
		<>
			<div className={s.wrapper}>
				<aside className={s.sidebar}>
					<h4 className={s.categoriesTitle}>Vyberte kategorii:</h4>
					<ul className={s.categories}>
						<li className={clsx(s.category, !router.query.category && s.isActive)}>
							<Link href={`${props.url}`}>
								<a>Vše</a>
							</Link>
						</li>
						{categories.map((category, index) => (
							<li
								key={`category-${index}`}
								className={clsx(
									s.category,
									router.query.category && decodeURI(router.query.category as string) === category
										? s.isActive
										: undefined
								)}>
								<Link href={`${props.url}?category=${encodeURIComponent(category)}`}>
									<a className={s.categoryBtn}>{category}</a>
								</Link>
							</li>
						))}
					</ul>
					<div className={s.backToFormBtnWrapper}>
						<p className={s.backToFormLabel}>
							Nenašli jste v odpovědích to, co potřebujete? Rádi zodpovíme váš dotaz.
						</p>
						<Link href={`${props.url}#form`}>
							<a className={s.backToFormBtn}>Zeptat se</a>
						</Link>
					</div>
				</aside>
				<div className={s.content}>
					<div className={s.contentWrapper}>
						<div className={s.cover}>
							<p className={s.coverTitle}>{props.counselingPage.title}</p>
							<div className={s.coverImage}>
								{props.counselingPage.image?.url && (
									<Image
										alt=""
										src={props.counselingPage.image?.url}
										width={props.counselingPage.image.width}
										height={props.counselingPage.image.height}
									/>
								)}
							</div>
						</div>
						<div className={clsx(!isContentVisible && s.isHidden)}>
							<div className={clsx(s.Blocks, 'blocksToLeft')}>
								<BlockRenderer blocks={props.counselingPage.content?.blocks ?? []} />
							</div>

							{questionsLimit !== 0 && questionsLimit - questionsTaken > 0 && (
								<div className={s.questionsLimit}>
									Počet dotazů, které dnes ještě můžeme přijmout: {questionsLimit - questionsTaken}
								</div>
							)}

							{props.counselingPage.newsletterForm && (
								<div className={s.newsletterForm} id={'form'}>
									<NewsletterForm
										newsletter={props.counselingPage.newsletterForm}
										hiddenTitle
										isCounselingForm
										formFieldsHidden={questionsLimit > 0 && questionsLimit - questionsTaken <= 0}
									/>
								</div>
							)}

							{questionsLimit !== 0 && questionsLimit - questionsTaken === 0 && (
								<div className={s.questionsLimit}>
									Limit dotazů pro dnešní den byl vyčerpán. Svůj dotaz můžete zadat v dalších dnech.
									Rádi váš dotaz zodpovíme také po{' '}
									<a href="https://aperio.cz/tema/poradna-pro-rodice">telefonu nebo osobně.</a>
								</div>
							)}
						</div>

						<div>
							{!router.query.question && (
								<div className={s.sectionTitle}>
									<p>Kategorie:</p>
									<p>—</p>
									<p>{router.query.category || 'vše'}</p>
								</div>
							)}

							{questions?.map((question, index) => (
								<React.Fragment key={`question-${index}`}>
									<QuestionAnswer {...question} baseUrl={props.url} />
								</React.Fragment>
							))}

							{maxPage > 1 && (
								<div className={s.pagination}>
									<Pagination
										current={router.query.p ? parseInt(router.query.p as string, 10) : 1}
										maxPage={maxPage}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
