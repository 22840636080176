import type { CommonPageProps } from '../../pages/[[...page]]'
import { BlockRenderer } from './blocks/BlockRenderer'
import s from './Page404.module.sass'

export type NotFoundProps = {
	page404: NonNullable<CommonPageProps['data']['notFoundPage']>
}

export function Page404(props: NotFoundProps) {
	return (
		<div className={s.Page404}>
			<BlockRenderer blocks={props.page404.content?.blocks ?? []} />
		</div>
	)
}
