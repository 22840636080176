/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import cn from 'clsx'
import type { DetailedHTMLProps, VideoHTMLAttributes } from 'react'
import React from 'react'
import type { VideoResult } from '../data/content/VideoFragment'
import SvgPlayBtn from '../svg/sprites/PlayBtn'
import { ContemberImage } from './ContemberImage'
import s from './ContemberVideo.module.sass'

export function ContemberVideo(
	props: {
		video: VideoResult
		sizes?: string
		gif?: boolean
		forcePosterBeforePlay?: boolean
	} & Partial<DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>>
) {
	const {
		video: { src, width, height, type, poster },
		forcePosterBeforePlay,
		gif,
		...elProps
	} = props

	const [videoReady, setVideoReady] = React.useState(false)
	const [playing, setPlaying] = React.useState(false)
	const [posterReady, setPosterReady] = React.useState(!!poster)
	const videoRef = React.useRef<HTMLVideoElement>(null)

	if (src && width && height && type) {
		return (
			<div
				onClick={() => {
					if (videoRef.current) {
						if (videoRef.current.paused) {
							videoRef.current.play()
						} else {
							videoRef.current.pause()
						}
					}
				}}
				className={cn(
					s.Container,
					posterReady && s.posterLoaded,
					videoReady && s.videoReady,
					playing && s.isPlaying,
					gif && s.cover,
					forcePosterBeforePlay && s.ForcePosterBeforePlay
				)}>
				<div
					className={s.Ratio}
					style={{ paddingTop: `${Math.round((100000 * height) / width) / 1000}%` }}></div>
				<video
					autoPlay={gif}
					muted={gif}
					loop={gif}
					playsInline={gif}
					{...elProps}
					className={s.Video}
					onCanPlay={() => setVideoReady(true)}
					onPlay={() => {
						setVideoReady(true)
						setPlaying(true)
					}}
					onPause={() => {
						setPlaying(false)
					}}
					ref={videoRef}>
					<source src={src} />
				</video>
				{poster && (
					<div className={s.Poster}>
						<ContemberImage
							image={poster}
							layout="fill"
							objectFit="cover"
							onLoad={() => setPosterReady(true)}
							sizes={props.sizes}
						/>
					</div>
				)}
				<span className={s.PlayBtn}>
					<SvgPlayBtn />
				</span>
			</div>
		)
	}

	return null
}
