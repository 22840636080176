import cn from 'clsx'
import Image from 'next/image'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { BlockRenderer } from './blocks/BlockRenderer'
import { Cover } from './Cover'
import s from './LawPage.module.sass'
import { Link } from './Link'

export type lawProps = {
	lawPage: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['lawPage']>
}

export function LawPage(props: CommonPageProps & lawProps) {
	return (
		<div className={s.LawPage}>
			<div className={s.Cover}>{props.lawPage.cover && <Cover cover={props.lawPage.cover} />}</div>
			<div className={s.LawBoxes}>
				<div className={s.LawContainer}>
					{props.lawPage.boxes.map((item, i) => (
						<div key={i} className={s.LawBoxContainer}>
							<div className={s.LawBox}>
								<div className={s.LawTitleBox}>
									<div>
										<p className={s.LawBoxTitle}>{item.headlineTop}</p>
										{item.headlineBottom && <span className={cn(s.LawBoxTitle, s.Dash)}>—</span>}
										<p className={s.LawBoxTitle}>{item.headlineBottom}</p>
									</div>
									<div className={s.BoxImage}>
										{item.image?.url && item.image?.width && item.image?.height && (
											<Image
												alt=""
												src={item.image.url}
												width={item.image.width}
												height={item.image.height}
											/>
										)}
									</div>
								</div>
								<ul className={s.BoxList}>
									{item.list?.items.map((item, i) => (
										<li key={i} className={s.BoxItem}>
											{!item.link?.externalLink && !item.link?.internalLink && (
												<p>{item.link?.title}</p>
											)}
											{item.link && <Link link={item.link} className={s.Link} />}
										</li>
									))}
								</ul>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className={s.Content}>
				<BlockRenderer blocks={props.lawPage.content?.blocks ?? []} />
			</div>
		</div>
	)
}
