import * as React from 'react'

const SvgArrowPartnersL = () => (
	<svg width={16} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="m7.317 0 1.898 1.885-4.118 4.118H16v2.661H5.097l4.118 4.117-1.898 1.886L0 7.333 7.317 0Z"
			fill="#1A1A1A"
			opacity={0.3}
		/>
	</svg>
)

export default SvgArrowPartnersL
