import clsx from 'clsx'
import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import React from 'react'
import { Blog } from '../app/components/Blog'
import { BlogPage } from '../app/components/BlogPage'
import type { Breadcrumb } from '../app/components/Breadcrumbs'
import { CookieBar } from '../app/components/CookieBar'
import { CounselingPage } from '../app/components/CounselingPage'
import { Course } from '../app/components/Course'
import { CoursePage } from '../app/components/CoursePage'
import { DonatePage } from '../app/components/DonatePage'
import { Footer } from '../app/components/Footer'
import { Header } from '../app/components/Header'
import { HomePage } from '../app/components/HomePage'
import { LawPage } from '../app/components/LawPage'
import { ModalBanner } from '../app/components/ModalBanner'
import { Page } from '../app/components/Page'
import { Page404 } from '../app/components/Page404'
import { SeoHead } from '../app/components/SeoHead'
import { Topic } from '../app/components/Topic'
import { TopicPage } from '../app/components/TopicPage'
import { ActiveUrlsContext } from '../app/contexts/ActiveUrlsContext'
import { LecturersContext } from '../app/contexts/LecturersContext'
import { LocaleContext } from '../app/contexts/LocaleContext'
import { combineUrl } from '../app/data/combineUrl'
import { BlogFragment } from '../app/data/content/BlogFragment'
import { ContentFragment } from '../app/data/content/ContentFragment'
import { CookieBarFragment } from '../app/data/content/CookieBarFragment'
import { CourseFragment } from '../app/data/content/CourseFragment'
import {
	CoverWithDownloadFragment,
	CoverWithIllustrationFragment,
	CoverWithImageFragment,
	CoverWithVideoFragment,
} from '../app/data/content/CoverFragment'
import { ImageFragment } from '../app/data/content/ImageFragment'
import { LinkFragment } from '../app/data/content/LinkFragment'
import { ModalBannerFragment } from '../app/data/content/ModalBannerFragment'
import { NewsletterFragment } from '../app/data/content/NewsletterFragment'
import { PageLocalesFragment } from '../app/data/content/PageLocalesFragment'
import { SeoFragment } from '../app/data/content/SeoFragment'
import { filterNonEmpty } from '../app/utils/filterNonEmpty'
import {
	ContentReferenceType,
	ContentType,
	One,
	OrderDirection,
	Thunder,
} from '../generated/content'
import { RespondWithRedirect, serverPropsHandler } from '../packages/@necktip/next/api/handlers'
import { getZeusConnection } from '../packages/@necktip/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const activeUrl = props.data.getLinkable?.url ?? null
	const blog = props.data.getLinkable?.blog
	const blogPage = props.data.getLinkable?.blogPage
	const course = props.data.getLinkable?.course
	const coursePage = props.data.getLinkable?.coursePage
	const donatePage = props.data.getLinkable?.donatePage
	const footer = props.data.getFooterMenuLocale
	const header = props.data.getHeaderMenuLocale
	const homePage = props.data.getLinkable?.homePage
	const counselingPage = props.data.getLinkable?.counselingPage
	const page = props.data.getLinkable?.page
	const lawPage = props.data.getLinkable?.lawPage
	const topic = props.data.getLinkable?.topic
	const topicPage = props.data.getLinkable?.topicPage
	const modalBanner = props.data.getModalBanner
	const cookieBar = props.data.getCookieBar
	const notFoundPage = props.data.notFoundPage

	const lecturers =
		props.data.getLinkable?.course?.root?.lecturers
			.map((item) => {
				return item.lecturer
			})
			.filter(filterNonEmpty) ?? null

	const pageLocales = [
		blog,
		blogPage,
		course,
		coursePage,
		donatePage,
		homePage,
		counselingPage,
		page,
		lawPage,
		topic,
		topicPage,
		notFoundPage,
	]
		.filter(filterNonEmpty)
		.map((page) => {
			if ('root' in page) {
				const root = page.root
				if (root && 'locales' in root) {
					return root.locales
				}
			}
		})
		.filter(filterNonEmpty)[0]

	const seo =
		blog?.seo ??
		blogPage?.seo ??
		course?.seo ??
		coursePage?.seo ??
		donatePage?.seo ??
		page?.seo ??
		homePage?.seo ??
		counselingPage?.seo ??
		lawPage?.seo ??
		topic?.seo ??
		topicPage?.seo ??
		notFoundPage?.seo ??
		null

	const activeUrls = React.useMemo(
		() => [activeUrl, ...props.activeUrls].filter(filterNonEmpty),
		[activeUrl, props.activeUrls]
	)
	return (
		<div className={clsx(course && 'pageType-course')}>
			<Head>
				<title>Aperio</title>
				<link rel="icon" href="/favicon.ico" />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
				<meta name="msapplication-TileColor" content="#ffb000" />
				<meta name="theme-color" content="#ffffff" />
				<meta
					name="google-site-verification"
					content="_rxYD5ZRlNVdSubmLs3ajj-X9XwQVNb5kx1p0wtTB1o"
				/>
				{course?.hideInSearching && <meta name="robots" content="noindex, nofollow" />}
				{counselingPage?.hideInSearching && <meta name="robots" content="noindex, nofollow" />}
			</Head>
			{seo && <SeoHead seo={seo} locale={props.locale} />}
			<LocaleContext.Provider value={props.locale}>
				<PageContext.Provider value={props}>
					<LecturersContext.Provider value={lecturers}>
						<ActiveUrlsContext.Provider value={activeUrls}>
							{header && (
								<Header
									translations={pageLocales ?? []}
									header={header}
									homePage={props.data.getHomePageLocale}
									breadcrumbs={props.breadcrumbs}
								/>
							)}
							{blog && <Blog {...props} blog={blog} />}
							{blogPage && <BlogPage {...props} blogPage={blogPage} />}
							{course && <Course {...props} course={course} />}
							{coursePage && <CoursePage {...props} coursePage={coursePage} />}
							{donatePage && <DonatePage {...props} donatePage={donatePage} />}
							{page && <Page page={page} />}
							{homePage && <HomePage {...props} homePage={homePage} />}
							{counselingPage && <CounselingPage {...props} counselingPage={counselingPage} />}
							{lawPage && <LawPage {...props} lawPage={lawPage} />}
							{topic && <Topic {...props} topic={topic} />}
							{topicPage && <TopicPage {...props} topicPage={topicPage} />}
							{notFoundPage && <Page404 page404={notFoundPage} />}
							{modalBanner?.localesByLocale?.isActive && <ModalBanner modalBanner={modalBanner} />}
							{cookieBar && <CookieBar cookieBar={cookieBar} />}
							{footer && <Footer footer={footer} />}
						</ActiveUrlsContext.Provider>
					</LecturersContext.Provider>
				</PageContext.Provider>
			</LocaleContext.Provider>
		</div>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const urlInfo = combineUrl(context)

	const url = `${urlInfo.path}`

	const locale = context.locale ?? context.defaultLocale ?? 'cz'

	const data = await content.query({
		// ...SiteFragment(urlInfo.locale),

		getHomePageLocale: [
			{
				by: {
					root: { unique: One.One },
					locale: {
						code: locale,
					},
				},
			},
			{
				link: [
					{},
					{
						url: true,
					},
				],
			},
		],
		getFooterMenuLocale: [
			{
				by: {
					root: { unique: One.One },
					locale: {
						code: locale,
					},
				},
			},
			{
				socialLinks: [
					{},
					{
						link: [{}, LinkFragment()],
						icon: [
							{},
							{
								id: true,
								url: true,
								width: true,
								height: true,
							},
						],
					},
				],
				image: [{}, ImageFragment()],
				columns: [
					{},
					{
						text: true,
					},
				],
			},
		],
		getHeaderMenuLocale: [
			{
				by: {
					root: { unique: One.One },
					locale: {
						code: locale,
					},
				},
			},
			{
				locale: [
					{},
					{
						code: true,
					},
				],
				logoMobile: [{}, ImageFragment()],
				logo: [{}, ImageFragment()],
				headerLinks: [
					{},
					{
						items: [
							{ orderBy: [{ order: OrderDirection.asc }] },
							{
								link: [{}, LinkFragment()],
							},
						],
					},
				],
				button: [{}, LinkFragment()],
			},
		],
		listPartner: [
			{ orderBy: [{ order: OrderDirection.asc }] },
			{
				id: true,
				logo: [{}, ImageFragment()],
				link: [{}, LinkFragment()],
			},
		],
		listTopic: [
			{ orderBy: [{ order: OrderDirection.asc }] },
			{
				id: true,
				localesByLocale: [
					{ by: { locale: { code: locale } } },
					{
						title: true,
					},
				],
				course: [{}, CourseFragment(locale)],
			},
		],
		getModalBanner: [{ by: { unique: One.One } }, ModalBannerFragment(locale)],
		getCookieBar: [{ by: { unique: One.One } }, CookieBarFragment(locale)],
		getLinkable: [
			{ by: { url } },
			{
				id: true,
				url: true,
				redirect: [
					{},
					{
						target: [
							{},
							{
								url: true,
							},
						],
						targetUrl: true,
					},
				],
				page: [
					{},
					{
						seo: [{}, SeoFragment()],
						title: true,
						content: [{}, ContentFragment(locale)],
					},
				],
				topicPage: [
					{},
					{
						seo: [{}, SeoFragment()],
						title: true,
						link: [{}, { url: true }],
						root: [{}, PageLocalesFragment()],
						cover: [{}, CoverWithIllustrationFragment()],
						topics: [
							{ orderBy: [{ order: OrderDirection.asc }] },
							{
								type: true,
								image: [{}, ImageFragment()],
								topic: [
									{},
									{
										localesByLocale: [
											{ by: { locale: { code: locale } } },
											{
												cover: [
													{},
													{
														headlineTop: true,
														headlineBottom: true,
													},
												],
												link: [
													{},
													{
														url: true,
													},
												],
											},
										],
										course: [{}, CourseFragment(locale)],
									},
								],
							},
						],
						externals: [
							{ orderBy: [{ order: OrderDirection.asc }] },
							{
								type: true,
								image: [{}, ImageFragment()],
								text: true,
								textBottom: true,
								url: true,
							},
						],
					},
				],
				coursePage: [
					{},
					{
						seo: [{}, SeoFragment()],
						root: [{}, PageLocalesFragment()],
						title: true,
						link: [{}, { url: true }],
						cover: [{}, CoverWithIllustrationFragment()],
						content: [{}, ContentFragment(locale)],
						topics: [
							{ orderBy: [{ order: OrderDirection.asc }] },
							{
								topic: [
									{},
									{
										id: true,
										localesByLocale: [
											{ by: { locale: { code: locale } } },
											{
												cover: [
													{},
													{
														headlineTop: true,
														headlineBottom: true,
													},
												],
												link: [
													{},
													{
														url: true,
													},
												],
											},
										],
										course: [{ orderBy: [{ order: OrderDirection.asc }] }, CourseFragment(locale)],
									},
								],
							},
						],
					},
				],
				lawPage: [
					{},
					{
						seo: [{}, SeoFragment()],
						title: true,
						link: [{}, { url: true }],
						cover: [{}, CoverWithDownloadFragment(locale)],
						boxes: [
							{ orderBy: [{ order: OrderDirection.asc }] },
							{
								headlineTop: true,
								headlineBottom: true,
								image: [{}, ImageFragment()],
								list: [
									{},
									{
										items: [
											{ orderBy: [{ order: OrderDirection.asc }] },
											{
												link: [{}, LinkFragment()],
											},
										],
									},
								],
							},
						],
						content: [{}, ContentFragment(locale)],
					},
				],
				blogPage: [
					{},
					{
						seo: [{}, SeoFragment()],
						title: true,
						link: [{}, { url: true }],
						cover: [{}, CoverWithImageFragment()],
					},
				],
				donatePage: [
					{},
					{
						seo: [{}, SeoFragment()],
						title: true,
						link: [{}, { url: true }],
						cover: [{}, CoverWithImageFragment()],
						root: [
							{},
							{
								banners: [
									{ orderBy: [{ order: OrderDirection.asc }] },
									{
										type: true,
										localesByLocale: [
											{ by: { locale: { code: locale } } },
											{
												primaryText: true,
												secondaryText: true,
												tertiaryText: true,
												height: true,
												link: [{}, LinkFragment()],
											},
										],
										image: [{}, ImageFragment()],
									},
								],
							},
						],
						content: [{}, ContentFragment(locale)],
					},
				],
				course: [
					{},
					{
						seo: [{}, SeoFragment()],
						published: true,
						hideInSearching: true,
						title: true,
						titleBottom: true,
						description: true,
						loginLink: [{}, LinkFragment()],
						root: [{}, CourseFragment(locale)],
						content: [{}, ContentFragment(locale)],
					},
				],
				topic: [
					{},
					{
						seo: [{}, SeoFragment()],
						cover: [{}, CoverWithVideoFragment()],
						content: [{}, ContentFragment(locale)],
					},
				],
				blog: [
					{},
					{
						seo: [{}, SeoFragment()],
						root: [
							{},
							{
								createdAt: true,
								topics: [
									{},
									{
										localesByLocale: [
											{ by: { locale: { code: locale } } },
											{
												title: true,
												titleBottom: true,
												link: [
													{},
													{
														url: true,
													},
												],
											},
										],
									},
								],
							},
						],
						published: true,
						accentFirst: [
							{},
							{
								image: [{}, ImageFragment()],
							},
						],
						accentSecond: [
							{},
							{
								image: [{}, ImageFragment()],
							},
						],
						accentThird: [
							{},
							{
								image: [{}, ImageFragment()],
							},
						],
						content: [{}, ContentFragment(locale)],
						shareHeadline: true,
						cover: [{}, CoverWithImageFragment()],
					},
				],
				homePage: [
					{},
					{
						seo: [{}, SeoFragment()],
						accentFirst: [
							{},
							{
								image: [{}, ImageFragment()],
							},
						],
						accentSecond: [
							{},
							{
								image: [{}, ImageFragment()],
							},
						],
						root: [{}, PageLocalesFragment()],
						cover: [{}, CoverWithVideoFragment()],
						content: [{}, ContentFragment(locale)],
						link: [{}, { url: true }],
					},
				],
				counselingPage: [
					{},
					{
						seo: [{}, SeoFragment()],
						title: true,
						hideInSearching: true,
						image: [{}, ImageFragment()],
						content: [{}, ContentFragment(locale)],
						newsletterForm: [{}, NewsletterFragment(locale)],
					},
				],
			},
		],
	})
	if (data.getLinkable?.redirect) {
		const destination =
			data.getLinkable?.redirect.targetUrl ?? data.getLinkable?.redirect.target?.url
		if (destination) {
			throw new RespondWithRedirect({
				destination,
				permanent: false,
			})
		}
		throw new Error()
	}

	const notFound = Object.values(data.getLinkable ?? {}).filter(filterNonEmpty).length === 0

	if (notFound) {
		context.res.statusCode = 404
	}

	const notFoundPage = notFound
		? await content.query({
				getPage404Locale: [
					{ by: { root: { unique: One.One }, locale: { code: locale } } },
					{
						seo: [{}, SeoFragment()],
						id: true,
						title: true,
						content: [{}, ContentFragment(locale)],
					},
				],
		  })
		: null

	const contents = [
		data.getLinkable?.homePage?.content,
		data.getLinkable?.counselingPage?.content,
		data.getLinkable?.blog?.content,
		data.getLinkable?.page?.content,
		data.getLinkable?.coursePage?.content,
		data.getLinkable?.lawPage?.content,
		data.getLinkable?.topic?.content,
		notFoundPage?.getPage404Locale?.content,
	]

	const references = contents
		.flatMap((c) => c?.blocks.flatMap((b) => b.references ?? []) ?? [])
		.filter(filterNonEmpty)

	const requireExtra = {
		allBlogPosts: Boolean(data.getLinkable?.blogPage),
		allCourses: Boolean(data.getLinkable?.coursePage),
		allPartners: false,
		topicBlogPosts: [] as string[],
		topicCourses: [] as string[],
	}

	references.forEach((ref) => {
		switch (ref.type) {
			case ContentReferenceType.partners:
				requireExtra.allPartners = true
				break
			case ContentReferenceType.blogPosts:
				switch (ref.blogPostType) {
					case ContentType.all:
						if (ref.topic?.id) {
							requireExtra.topicBlogPosts.push(ref.topic.id)
						} else {
							requireExtra.allBlogPosts = true
						}
						break
				}
				break
			case ContentReferenceType.courses:
				switch (ref.courseType) {
					case ContentType.all:
						if (ref.topic?.id) {
							requireExtra.topicCourses.push(ref.topic.id)
						} else {
							requireExtra.allCourses = true
						}
						break
				}
				break
		}
	})

	const allPartners = requireExtra.allPartners
		? await content
				.query({
					listPartner: [
						{
							orderBy: [{ order: OrderDirection.asc }],
						},
						{
							title: true,
							link: [{}, LinkFragment()],
							logo: [{}, ImageFragment()],
						},
					],
				})
				.then((data) => data.listPartner)
		: null

	const allBlogPosts = requireExtra.allBlogPosts
		? await content
				.query({
					listBlogLocale: [
						{
							orderBy: [{ root: { createdAt: OrderDirection.desc } }],
							filter: {
								locale: {
									code: { eq: locale },
								},
							},
						},
						{
							root: [{}, BlogFragment(locale)],
						},
					],
				})
				.then((data) => data.listBlogLocale)
		: null

	const allCourses = requireExtra.allCourses
		? await content
				.query({
					listCourseLocale: [
						{
							filter: {
								locale: {
									code: { eq: locale },
								},
							},
						},
						{
							root: [{}, CourseFragment(locale)],
						},
					],
				})
				.then((data) => data.listCourseLocale)
		: null

	const extras = {
		allBlogPosts,
		allPartners,
		allCourses,
		topicBlogPosts: Object.fromEntries(
			await Promise.all(
				requireExtra.topicBlogPosts.map((topic) =>
					content
						.query({
							listBlogLocale: [
								{
									orderBy: [{ root: { createdAt: OrderDirection.desc } }],
									filter: {
										locale: {
											code: { eq: locale },
										},
										root: {
											topics: { id: { in: [topic] } },
										},
									},
								},
								{
									root: [{}, BlogFragment(locale)],
								},
							],
						})
						.then((data) => [topic, data.listBlogLocale] as const)
				)
			)
		),
		topicCourses: Object.fromEntries(
			await Promise.all(
				requireExtra.topicCourses.map((topic) =>
					content
						.query({
							listCourseLocale: [
								{
									filter: {
										locale: {
											code: { eq: locale },
										},
										root: {
											topic: { id: { eq: topic } },
										},
									},
								},
								{
									root: [{}, CourseFragment(locale)],
								},
							],
						})
						.then((data) => [topic, data.listCourseLocale] as const)
				)
			)
		),
	}

	const breadcrumbs: Breadcrumb[] = []

	const homePage = data.getHomePageLocale

	const homePageBreadcrumb = {
		title: 'A',
		url: homePage?.link?.url ?? '#',
	}

	if (data.getLinkable?.course || data.getLinkable?.topic || data.getLinkable?.blog) {
		breadcrumbs.push(homePageBreadcrumb)

		const breadcrumbsData = await content.query({
			getTopicPageLocale: [
				{ by: { root: { unique: One.One }, locale: { code: locale } } },
				{
					title: true,
					link: [
						{},
						{
							url: true,
						},
					],
				},
			],
			getCoursePageLocale: [
				{ by: { root: { unique: One.One }, locale: { code: locale } } },
				{
					title: true,
					link: [
						{},
						{
							url: true,
						},
					],
				},
			],
			getBlogPageLocale: [
				{ by: { root: { unique: One.One }, locale: { code: locale } } },
				{
					title: true,
					link: [
						{},
						{
							url: true,
						},
					],
				},
			],
		})

		if (data.getLinkable?.topic) {
			if (breadcrumbsData.getTopicPageLocale?.link?.url) {
				breadcrumbs.push({
					url: breadcrumbsData.getTopicPageLocale.link.url,
					title: breadcrumbsData.getTopicPageLocale.title ?? '',
				})
			}
		}
		if (data.getLinkable?.course) {
			if (breadcrumbsData.getCoursePageLocale?.link?.url) {
				breadcrumbs.push({
					url: breadcrumbsData.getCoursePageLocale.link.url,
					title: breadcrumbsData.getCoursePageLocale.title ?? '',
				})
			}
		}
		if (data.getLinkable?.blog) {
			if (breadcrumbsData.getBlogPageLocale?.link?.url) {
				breadcrumbs.push({
					url: breadcrumbsData.getBlogPageLocale.link.url,
					title: breadcrumbsData.getBlogPageLocale.title ?? '',
				})
			}
		}

		if (data.getLinkable?.course) {
			// const topicBreadcrumbs: BreadcrumbItem[] = []

			// data.getLinkable.course.root?.topic.forEach((topic) => {
			// 	const t = topic.localesByLocale
			// 	if (!t) {
			// 		return
			// 	}
			// 	topicBreadcrumbs.push({
			// 		url: t.link?.url ?? '#',
			// 		title: [t.title, t.titleBottom].filter(Boolean).join(' - '),
			// 	})
			// })

			// breadcrumbs.push(topicBreadcrumbs)

			breadcrumbs.push({
				url: data.getLinkable.url,
				title: data.getLinkable.course.title + ' ' + data.getLinkable.course.titleBottom,
			})
		}
		if (data.getLinkable?.topic) {
			// const topicBreadcrumbs: BreadcrumbItem[] = []

			// data.getLinkable.course.root?.topic.forEach((topic) => {
			// 	const t = topic.localesByLocale
			// 	if (!t) {
			// 		return
			// 	}
			// 	topicBreadcrumbs.push({
			// 		url: t.link?.url ?? '#',
			// 		title: [t.title, t.titleBottom].filter(Boolean).join(' - '),
			// 	})
			// })

			// breadcrumbs.push(topicBreadcrumbs)

			breadcrumbs.push({
				url: data.getLinkable.url,
				title:
					data.getLinkable.topic.cover?.headlineTop +
					' ' +
					data.getLinkable.topic.cover?.headlineBottom,
			})
		}
		// else if (data.getLinkable?.topic) {
		// 	breadcrumbs.push({
		// 		url: data.getLinkable.url,
		// 		title: 'Téma',
		// 	})
		// }

		if (data.getLinkable?.blog) {
			// const topicBreadcrumbs: BreadcrumbItem[] = []

			// data.getLinkable.blog.root?.topics.forEach((topics) => {
			// 	const t = topics.localesByLocale
			// 	if (!t) {
			// 		return
			// 	}
			// 	topicBreadcrumbs.push({
			// 		url: t.link?.url ?? '#',
			// 		title: [t.title, t.titleBottom].filter(Boolean).join(' - '),
			// 	})
			// })

			// breadcrumbs.push(topicBreadcrumbs)

			breadcrumbs.push({
				url: null,
				title: data.getLinkable.blog.cover?.headlineTop ?? '',
			})
		}
	} else {
		const generic =
			data.getLinkable?.lawPage ||
			data.getLinkable?.topicPage ||
			data.getLinkable?.blogPage ||
			data.getLinkable?.coursePage

		if (generic) {
			breadcrumbs.push(homePageBreadcrumb)
			breadcrumbs.push({
				url: generic.link?.url ?? '#',
				title: generic.title ?? '',
			})
		}
	}
	return {
		props: {
			url,
			activeUrls: breadcrumbs
				.flatMap((b) => {
					if (Array.isArray(b)) {
						return b.map((bb) => bb.url)
					}
					return [b.url]
				})
				.filter(filterNonEmpty),
			urlInfo,
			data: {
				...data,
				notFoundPage: notFoundPage?.getPage404Locale ?? null,
			},
			breadcrumbs,
			requireExtra,
			extras,
			page: context.params?.page ?? null,
			serverTime: new Date().toString(),
			locale,
		},
	}
})

const PageContext = React.createContext<null | CommonPageProps>(null)

export function usePageContext() {
	const context = React.useContext(PageContext)

	if (!context) {
		throw new Error()
	}

	return context
}
