import { RichTextRenderer } from '@contember/react-client'
import { default as clsx, default as cn } from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import type {
	CoverWithDownloadResult,
	CoverWithIllustrationResult,
	CoverWithImageResult,
	CoverWithVideoResult,
} from '../data/content/CoverFragment'
import SvgArrowDownload from '../svg/sprites/ArrowDownload'
import { sendDataLayerFormSent } from '../utils/sendDataLayerFormSent'
import { ArrowLink } from './ArrowLink'
import { ContemberImage } from './ContemberImage'
import { ContemberVideo } from './ContemberVideo'
import s from './Cover.module.sass'
import { Link } from './Link'
import { ModalWrapper } from './ModalWrapper'
import { NewsletterForm } from './NewsletterForm'

export type CoverProps = {
	cover:
		| CoverWithVideoResult
		| CoverWithIllustrationResult
		| CoverWithImageResult
		| CoverWithDownloadResult
	isSmallTitle?: boolean
	coverImageRightPosition?: boolean
	isBlog?: boolean
}

export function Cover(props: CoverProps) {
	const { cover, isSmallTitle, coverImageRightPosition, isBlog } = props

	const router = useRouter()

	const formId = 'file' in cover ? cover.downloadForm?.form?.id : undefined
	const gaDatalayerIdentifier =
		'file' in cover ? cover.downloadForm?.form?.gaDatalayerIdentifier : undefined
	const isSuccess = Boolean(formId && router.query.success === formId)

	const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(isSuccess)

	useEffect(() => {
		if (isSuccess) {
			sendDataLayerFormSent(gaDatalayerIdentifier ?? 'nezarazenyFormular')
		}
	}, [isSuccess, gaDatalayerIdentifier])

	let text: null | JSX.Element = null
	let videoImage = <></>
	let video = <></>
	let image = <></>
	let listBoxes = <></>
	let file: null | JSX.Element = null

	if ('text' in cover) {
		text = <h3>{cover.text}</h3>
	}

	if ('video' in cover) {
		videoImage = (
			<div className={s.CoverImageBox}>
				{!cover?.video?.src && cover?.video?.poster && (
					<div className={s.Image}>
						<div>
							<ContemberImage image={cover?.video?.poster} layout="fill" objectFit="cover" />
						</div>
					</div>
				)}
			</div>
		)
	}

	if ('video' in cover) {
		video = (
			<div className={s.Video}>
				{cover?.video?.src && <ContemberVideo gif video={cover.video} />}
			</div>
		)
	}

	if ('image' in cover) {
		image = (
			<div
				className={cn(
					s.CoverImage,
					s.Column,
					coverImageRightPosition && s.CoverImageRightPosition
				)}>
				{cover?.image?.url && cover.image?.width && cover.image?.height && (
					<>
						<Image
							alt=""
							src={cover.image.url}
							objectFit="contain"
							objectPosition="right center"
							layout="fill"
						/>
						<div
							className={s.CoverImageRatio}
							style={{ paddingTop: `${(100 * cover.image.height) / cover.image.width}%` }}></div>
					</>
				)}
			</div>
		)
	}

	if ('listBoxes' in cover) {
		listBoxes = (
			<div className={s.BoxesBox}>
				{cover?.listBoxes.map((item, i) => (
					<div className={s.Box} key={i}>
						<p className={s.Headline}>{item.headline}</p>
						<div>
							{item.list?.items.map((item, i) => (
								<div className={s.LinkBox} key={i}>
									{item.link && <Link className={s.Link} link={item.link} />}
									{item.label && (
										<span
											className={s.Label}
											style={{
												color: item.labelColor?.colorHex,
											}}>
											<RichTextRenderer source={item.label ?? null} />
										</span>
									)}
									{item.highlight && <span className={s.LinkStar}>{item.highlight && '★'}</span>}
								</div>
							))}
						</div>
						{item.link && (
							<div className={s.LinkSection}>
								<ArrowLink link={item.link} text={item.link?.title ?? ''} />
							</div>
						)}
					</div>
				))}
			</div>
		)
	}

	if ('file' in cover) {
		file = (
			<>
				<div className={s.FileBox}>
					<button
						className={clsx(s.LawDownloadLink, 'gtm_click2modal')}
						type="button"
						onClick={() => setIsDownloadModalOpen(true)}>
						{cover?.fileName}
					</button>
					<SvgArrowDownload />
				</div>
				{cover.downloadForm && (
					<ModalWrapper
						isOpen={isDownloadModalOpen}
						closeModal={() => setIsDownloadModalOpen(false)}>
						<NewsletterForm
							newsletter={cover.downloadForm}
							file={cover?.file}
							redirectToSalesforce
						/>
					</ModalWrapper>
				)}
			</>
		)
	}

	return (
		<div className={clsx(s[String(cover.__typename)], isBlog && s.isBlog)}>
			<div className={s.AspectRatioContainer}>
				<div className={s.AspectRatioContent}>
					<div className={s.Cover}>
						<h1 className={cn(s.Title, isSmallTitle && s.isSmallTitle)}>
							<span className={s.TitlePrimary}>{cover?.headlineTop}</span>
							{cover?.headlineBottom && <span className={s.Dash}>—</span>}
							<span className={s.TitleSecondary}>{cover?.headlineBottom}</span>
						</h1>
					</div>
					{(text || file) && (
						<div className={cn(s.Column, s.Desktop)}>
							{text}

							{file}
						</div>
					)}

					{video}

					{videoImage}

					{image}
				</div>
				<div className={s.AspectRatio}></div>
			</div>
			{listBoxes}
			{(text || file) && (
				<div className={s.Mobile}>
					{(text || file) && (
						<div className={s.MobileText}>
							{text}

							{file}
						</div>
					)}
				</div>
			)}
		</div>
	)
}
