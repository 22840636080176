import * as React from 'react'

const SvgMinus = () => (
	<svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx={16} cy={16} r={16} fill="#FFB000" />
		<path fillRule="evenodd" clipRule="evenodd" d="M7 18.339h17.678v-3H7v3Z" fill="#1A1A1A" />
	</svg>
)

export default SvgMinus
