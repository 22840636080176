/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import type { CookieBarResult } from '../data/content/CookieBarFragment'
import styles from './CookieBar.module.sass'

function consent(options: {
	technicalCookie: boolean
	analyticalCookie?: boolean
	marketingCookie?: boolean
}) {
	const data = {
		date: new Date().toISOString(),
		options,
	}
	window.localStorage.setItem('cookieBarConsent', JSON.stringify(data))
}

export type CookieBarProps = {
	cookieBar: CookieBarResult
}

export const CookieBar = (props: CookieBarProps) => {
	const [close, setClose] = useState(false)
	const [displayCheckbox, setDisplayCheckbox] = useState(false)

	useEffect(() => {
		const isCookieBarClosed = window.localStorage.getItem('cookieBarClosed')
		if (isCookieBarClosed) {
			setClose(true)
		}
	}, [])

	return (
		<div className={clsx(styles.wrapper, close && styles.close)}>
			<div className={styles.title}>
				{props.cookieBar?.localesByLocale?.title}
				<div
					className={styles.crossButton}
					role="button"
					onClick={() => {
						setClose(true)
						window.localStorage.setItem('cookieBarClosed', 'close-button')
					}}>
					<div className={styles.line1}></div>
					<div className={styles.line2}></div>
				</div>
			</div>
			{props.cookieBar?.localesByLocale?.text && (
				<div className={styles.text}>
					<RichTextRenderer source={props.cookieBar?.localesByLocale?.text} />
				</div>
			)}
			<form
				method="post"
				className={clsx(styles.checkboxWrapper, displayCheckbox && styles.displayCheckbox)}
				onSubmit={(e) => {
					e.preventDefault()
					const formData = new FormData(e.currentTarget)

					setClose(true)
					consent({
						technicalCookie: true,
						analyticalCookie: Boolean(formData.get('analyticalCookie')),
						marketingCookie: Boolean(formData.get('marketingCookie')),
					})
					window.localStorage.setItem('cookieBarClosed', 'advanced-button')
				}}>
				<label className={styles.checkBox}>
					<input type="checkbox" disabled name="technicalCookie" />

					<span>{props.cookieBar?.localesByLocale?.technicalCookie}</span>
				</label>

				<label className={styles.checkBox}>
					<input type="checkbox" name="analyticalCookie" />

					{props.cookieBar?.localesByLocale?.analyticalCookie}
				</label>

				<label className={styles.checkBox}>
					<input type="checkbox" name="marketingCookie" />

					{props.cookieBar?.localesByLocale?.marketingCookie}
				</label>
				<button type="submit" className={styles.detailButtonTitle}>
					{props.cookieBar?.localesByLocale?.acceptSelectedButton}
				</button>
				<button
					className={styles.backButton}
					onClick={() => setDisplayCheckbox(!displayCheckbox)}
					type="button">
					{props.cookieBar?.localesByLocale?.backButton}
				</button>
			</form>
			<div className={clsx(styles.buttonWrapper, displayCheckbox && styles.hideButtons)}>
				<button
					className={styles.rejectButton}
					onClick={() => {
						setDisplayCheckbox(!displayCheckbox)
					}}>
					{props.cookieBar?.localesByLocale?.moreButton}
				</button>
				<button
					className={styles.consentButton}
					onClick={() => {
						setClose(true)
						window.localStorage.setItem('cookieBarClosed', 'default-button')
						consent({ technicalCookie: true, analyticalCookie: true, marketingCookie: true })
					}}>
					{props.cookieBar?.localesByLocale?.consentButton}
				</button>
			</div>
		</div>
	)
}
