import * as React from 'react'

const SvgArrow = () => (
	<svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx={16} cy={16} r={16} fill="#FFB000" />
		<path
			d="m16.683 9-1.898 1.885 4.118 4.118H8v2.661h10.903l-4.118 4.117 1.898 1.886L24 16.333 16.683 9z"
			fill="#1A1A1A"
		/>
	</svg>
)

export default SvgArrow
