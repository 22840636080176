import * as React from 'react'

const SvgHeart = () => (
	<svg width={61} height={63} fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#Heart_svg__a)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M36.564 26.62c3.294-2.939 6.283-4.644 9.552-5.786 2.555-.949 5.151-1.27 7.726-1.201 2.725.155 3.674 1.303 3.755 3.965a13.46 13.46 0 0 1-1.312 6.491c-2.303 4.715-4.822 9.104-8.702 12.713-2.06 1.837-4.096 3.284-6.435 4.557a83.578 83.578 0 0 1-14.851 5.563c-1.19.323-2.359.256-3.852.405-.21-3.138-1.242-5.542-2.34-8.183-.624-1.365-.924-2.945-1.31-4.374-.322-1.19-.58-2.143-1.226-3.118-3.405-5.978-4.451-12.212-3.831-18.898.443-4.977 3.155-8.653 6.878-9.535 5.93-1.225 9.691-.072 13.118 5.516 1.574 2.512 2.585 5.306 2.492 8.27.106.866.277 1.97.338 3.615zM24.205 49.91c4.134-1.249 7.857-2.13 11.364-3.338 7.728-2.608 13.342-7.837 16.97-15.212.892-1.903 1.416-4.218 1.853-6.381.306-1.233-.579-2.143-1.898-2.297-.39-.022-.779-.044-1.255.085-4.112.86-8.288 1.482-11.216 4.832-.672.821-1.798 1.382-2.622 2.117-.91.886-1.757 2.01-3.205 1.38-1.599-.716-1.358-2.187-1.116-3.659.482-2.942.488-5.755-.436-8.7-.965-3.572-5.004-6.694-8.833-6.678-2.899.147-5.302 1.183-6.476 3.93-1.088 2.595-1.721 5.45-1.25 8.135a36.449 36.449 0 0 0 2.703 10.002c1.442 3.443 2.559 7.102 3.763 10.61.688 1.602.988 3.183 1.654 5.175z"
				fill="#FFFBFB"
			/>
		</g>
		<defs>
			<clipPath id="Heart_svg__a">
				<path fill="#fff" transform="rotate(29.848 9.623 42.81)" d="M0 0h44.114v45.374H0z" />
			</clipPath>
		</defs>
	</svg>
)

export default SvgHeart
