import Image from 'next/image'
import type { CommonPageProps } from '../../pages/[[...page]]'
import SvgArrow from '../svg/sprites/Arrow'
import { BlockRenderer } from './blocks/BlockRenderer'
import { Cover } from './Cover'
import s from './DonatePage.module.sass'
import { Link } from './Link'

export type donateProps = {
	donatePage: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['donatePage']>
}

export function DonatePage(props: CommonPageProps & donateProps) {
	return (
		<div className={s.DonatePage}>
			<div className={s.Cover}>
				{props.donatePage.cover && (
					<Cover coverImageRightPosition={true} cover={props.donatePage.cover} isSmallTitle />
				)}
			</div>
			<div className={s.BannersBox}>
				<div className={s.Banners}>
					{props.donatePage.root?.banners.map((item, i) => (
						<div className={s.BannerContainer} key={i}>
							<div className={s.Banner}>
								{item.type === 'withEmbed' && (
									<div className={s.BannerEmbedBox}>
										<div className={s.BannerEmbedText}>
											<div className={s.Title}>{item.localesByLocale?.primaryText}</div>
											<div className={s.Subtitle}>{item.localesByLocale?.secondaryText}</div>
										</div>
										<div className={s.BannerEmbedImage}>
											{item.image?.url && item.image?.width && item.image?.height && (
												<Image
													alt=""
													src={item.image.url}
													width={item.image.width}
													height={item.image.height}
												/>
											)}
										</div>
									</div>
								)}
								{item.type === 'withLink' && (
									<>
										<div className={s.Title}>{item.localesByLocale?.primaryText}</div>
										<div className={s.Subtitle}>{item.localesByLocale?.secondaryText}</div>
										<div className={s.BannerImage}>
											{item.image?.url && item.image?.width && item.image?.height && (
												<Image
													alt=""
													src={item.image.url}
													width={item.image.width}
													height={item.image.height}
												/>
											)}
										</div>
									</>
								)}
								{item.localesByLocale?.tertiaryText && (
									<iframe
										width="100%"
										height={item.localesByLocale?.height ?? '251'}
										src={item.localesByLocale?.tertiaryText}
										scrolling="no"
										className={s.iFrame}
										title="iFrame"
									/>
								)}
								{item.localesByLocale?.link && (
									<div className={s.Button}>
										<Link link={item.localesByLocale?.link} />
										<SvgArrow />
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
			<BlockRenderer blocks={props.donatePage.content?.blocks ?? []} />
		</div>
	)
}
