import type { ReferenceRendererProps } from '@contember/react-client'
import { default as clsx } from 'clsx'
import Image from 'next/image'
import React, { useState } from 'react'
import { ContentType } from '../../../../generated/content'
import { usePageContext } from '../../../../pages/[[...page]]'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import ArrowSvg from '../../../svg/sprites/Arrow'
import { filterNonEmpty } from '../../../utils/filterNonEmpty'
import { ArrowLink } from '../../ArrowLink'
import { Blog } from '../../BlogPost'
import s from './BlogPosts.module.sass'

const LINE_LIMIT = 3

export function BlogPosts({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	const extras = usePageContext().extras

	const allBlogPosts = reference.topic
		? extras.topicBlogPosts[reference.topic.id]
		: extras.allBlogPosts

	const rawBlogPosts =
		reference.blogPostType === ContentType.all
			? (allBlogPosts ?? []).map((post) => post.root)
			: reference.blogPosts.map((post) => post.blogPost)

	const blogPosts = rawBlogPosts.filter(filterNonEmpty)

	const blogPostCount = blogPosts.length
	const darkBackground = reference.darkBackground
	const classes = darkBackground ? [s.DarkBackground] : []
	const showThumbnail = reference.showThumbnail ?? false
	const classThumb = showThumbnail ? [] : [s.HideThumbnail]
	const [isExpanded, setIsExpanded] = useState(false)
	const visiblePosts = isExpanded ? blogPosts : blogPosts.slice(0, 6)

	return (
		<div id={`section-${reference.block?.order}`} className={clsx(s.BlogPosts, classes)}>
			<div className={clsx(s.HeadlineBox, classThumb)}>
				<div className={clsx(s.Image, classThumb)}>
					{reference.image?.url && reference.image?.width && reference.image?.height && (
						<Image
							alt=""
							src={reference.image.url}
							width={reference.image.width}
							height={reference.image.height}
						/>
					)}
				</div>
				<div className={clsx(s.TitleBox, classThumb)}>
					<h3 className={clsx(s.Title, classThumb)}>{reference.primaryText}</h3>
					{reference.secondaryText && <span className={clsx(s.Title, classThumb, s.Dash)}>—</span>}
					<h3 className={clsx(s.Title, classThumb)}>{reference.secondaryText}</h3>
				</div>
			</div>
			<div>
				<div
					className={clsx(s.BlogPostsBox, blogPostCount > LINE_LIMIT ? s.MultiLine : s.SingleLine)}
					data-multiline={'false'}>
					{visiblePosts.map((post, i) => (
						<>
							{post.localesByLocale?.published && (
								<Blog showThumbnail={showThumbnail} post={post} key={i} />
							)}
						</>
					))}
					{!isExpanded && blogPosts.length > 6 && (
						<div className={clsx(s.ExpandedBtnWrapper, isExpanded && s.isExpanded)}>
							<button className={s.ExpandedBtn} onClick={() => setIsExpanded(true)}>
								{reference.tertiaryText || 'Další'}
							</button>
							<span className={s.Arrow}>
								<ArrowSvg />
							</span>
						</div>
					)}
				</div>
				{reference.link?.title && (
					<div className={s.ButtonBox}>
						<ArrowLink link={reference.link} text={reference.link.title} />
					</div>
				)}
			</div>
		</div>
	)
}
