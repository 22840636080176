import * as React from 'react'

const SvgArrowDownload = () => (
	<svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx={16} cy={16} r={16} fill="#FFB000" />
		<path
			d="m23.333 17.017-1.885-1.898-4.118 4.117V8.333H14.67v10.903l-4.117-4.117-1.886 1.898L16 24.333l7.333-7.316Z"
			fill="#1A1A1A"
		/>
	</svg>
)

export default SvgArrowDownload
