import { RichTextRenderer } from '@contember/react-client'
import cn from 'clsx'
import React from 'react'
import { z } from 'zod'
import { ContentReferenceType } from '../../../generated/content'
import type { ContentBlockReference, ContentResult } from '../../data/content/ContentFragment'
import { Link } from '../Link'
import s from './BlockRenderer.module.sass'
import * as referenceRenderersSource from './referenceRenderers'

// adapt React components with capitalized name to blocks names
const referenceRenderers = Object.fromEntries(
	Object.entries(referenceRenderersSource).map(([k, v]) => [
		k.charAt(0).toLowerCase() + k.slice(1),
		v,
	])
)

export function BlockWrapper(props: { textual?: boolean; children: React.ReactNode }) {
	return (
		<div className={cn(s.Block, props.textual && s.Textual)}>
			<div className={s.In}>{props.children}</div>
		</div>
	)
}

const elementRenderers = {
	internalAnchor(props: {
		children: React.ReactNode
		reference?: { id: string } & Partial<ContentBlockReference>
	}) {
		if (props.reference?.link) {
			return <Link link={props.reference.link}>{props.children}</Link>
		}
		return <span>{props.children}</span>
	},
}

const BlockMeta = z.object({
	formatVersion: z.literal(1),
	children: z.array(z.object({ type: z.string(), referenceId: z.string().optional().nullable() })),
})

export function BlockRenderer(props: {
	className?: string
	inClassName?: string
	blocks: ContentResult['blocks']
	sourceField?: string
	wrappedBlocks?: string[]
	textualWrapper?: React.ComponentType<{ children: React.ReactNode; textual?: boolean }>
	blockWrappers?: Record<string, React.ComponentType<{ children: React.ReactNode }> | undefined>
	alignLeft?: boolean
	groupClassName?: string
}) {
	;(global as unknown as { __DEV_MODE__: boolean }).__DEV_MODE__ =
		process.env.NODE_ENV === 'development'

	const blocks = React.useMemo(() => {
		const allReferences: ContentResult['blocks'][number]['references'] = []

		return props.blocks.map((block) => {
			allReferences.push(...block.references)

			return { ...block, references: allReferences }
		})
	}, [props.blocks])

	const groups = React.useMemo(() => {
		type Group = { backgroundHex: string | null; blocks: typeof blocks }
		const groups: Group[] = []

		let activeGroup: Group | null = null

		for (const block of blocks) {
			const refId = BlockMeta.parse(JSON.parse(block.json)).children[0].referenceId
			const ref = block.references.find((r) => r.id === refId)
			if (ref?.type === ContentReferenceType.background) {
				activeGroup = { backgroundHex: ref.backgroundColor?.colorHex ?? null, blocks: [] }
				groups.push(activeGroup)
			} else {
				if (!activeGroup) {
					activeGroup = { backgroundHex: null, blocks: [] }
					groups.push(activeGroup)
				}
				activeGroup.blocks.push(block)
			}
		}

		return groups
	}, [blocks])

	const renderEl = React.useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(el: any) => {
			if (el.element.type in elementRenderers) {
				return elementRenderers[el.element.type as keyof typeof elementRenderers](el)
			}
			if (el.element.type === 'listItem') {
				return el.fallback
			}
			if (el.element.type === 'scrollTarget') {
				return <span className={s.ScrollTarget} id={el.element.identifier}></span>
			}
			const CustomWrapper = el.referenceType ? props.blockWrappers?.[el.referenceType] : undefined

			if (CustomWrapper) {
				return <CustomWrapper>{el.fallback}</CustomWrapper>
			}

			const isTextual = el.element.type !== 'reference'

			const isWrapped = (props.wrappedBlocks ?? []).indexOf(el.referenceType ?? '') > -1

			if (isTextual || isWrapped) {
				const TextualBlock = props.textualWrapper ?? BlockWrapper
				return <TextualBlock textual={isTextual}>{el.fallback}</TextualBlock>
			}

			return el.fallback
		},
		[props.blockWrappers, props.textualWrapper, props.wrappedBlocks]
	)

	return (
		<div className={cn(s.Content, props.className, props.alignLeft && s.AlignLeft)}>
			<div className={s.ContentIn}>
				{groups.map((group, g) => (
					<div
						className={props.groupClassName}
						style={{ backgroundColor: group.backgroundHex ?? 'transparent' }}
						key={g}>
						<RichTextRenderer
							blocks={group.blocks}
							sourceField={props.sourceField ?? 'json'}
							referenceRenderers={referenceRenderers}
							renderElement={renderEl}
						/>
					</div>
				))}
			</div>
		</div>
	)
}
