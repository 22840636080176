import clsx from 'clsx'
import { useEffect, useState } from 'react'
import SvgShare from '../svg/sprites/Share'
import s from './Share.module.sass'

export type ShareProps = {
	title?: string
	shareTitle?: string
}

export function Share(props: ShareProps) {
	const [isSupporter, setIsSupported] = useState(false)
	useEffect(() => {
		if ('share' in navigator) {
			setIsSupported(true)
		}
	}, [])
	return (
		<div className={clsx(s.ShareHeadlineBox, isSupporter && s.Supported)}>
			{props.title && <p className={s.ShareHeadline}>{props.title}</p>}
			<div>
				<button
					type="button"
					onClick={async () => {
						try {
							await navigator.share({
								title: props.shareTitle ?? document.title,
								url: window.location.href,
							})
						} catch {}
					}}
					className={s.ShareButtons}>
					<SvgShare />
				</button>
			</div>
		</div>
	)
}
