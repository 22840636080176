import * as React from 'react'

const SvgHamburgerMobile = () => (
	<svg width={46} height={46} fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx={23} cy={23} r={23} fill="#FFB000" />
		<path stroke="#1A1A1A" strokeWidth={3} d="M10 16.5h27m-27 7h27m-27 7h27" />
	</svg>
)

export default SvgHamburgerMobile
