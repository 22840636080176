import * as z from 'zod'

export const NewsletterFormInput = () =>
	z.object({
		newsletter: z.string().nonempty(),
		email: z.string().email(),
		fileName: z.string(),
		fileUrl: z.string(),
	})

export type NewsletterFormInputData = z.infer<ReturnType<typeof NewsletterFormInput>>
