import type { ReferenceRendererProps } from '@contember/react-client'
import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import s from './Cover.module.sass'

export function Cover({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.Cover}>
			<div className={s.TextBox}>
				<h1 className={s.Title}>
					<RichTextRenderer source={reference.primaryText ?? null} />
				</h1>
				{reference.secondaryText && (
					<h3 className={s.Text}>
						<RichTextRenderer source={reference.secondaryText ?? null} />
					</h3>
				)}
			</div>
			<div className={s.Image}>
				{reference.image?.url && reference.image?.width && reference.image?.height && (
					<Image
						alt=""
						src={reference.image.url}
						width={reference.image.width}
						height={reference.image.height}
					/>
				)}
			</div>
		</div>
	)
}
