import * as React from 'react'

const SvgCloseBtn = () => (
	<svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx={16} cy={16} r={16} fill="#FFB000" />
		<path d="m10 10 12.5 12.5m0-12.5L10 22.5" stroke="#1A1A1A" strokeWidth={4} />
	</svg>
)

export default SvgCloseBtn
