import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import StarSvg from '../../../svg/sprites/Star'
import Breaklines from '../../Breaklines'
import s from './NumberedList.module.sass'

export function NumberedList({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div
			id={`section-${reference.block?.order}`}
			className={clsx(s.NumberedList, 'maybePaddingRight')}>
			{reference.primaryText && (
				<h3 className={s.Text}>
					<Breaklines source={reference.primaryText} />
				</h3>
			)}
			<h5 className={s.Subtitle}>{reference.secondaryText}</h5>
			{reference.textList?.items.map((item, i) => (
				<div key={i}>
					<p className={s.Number}>
						{i++ + 1} <StarSvg />
					</p>

					<div>
						<RichTextRenderer source={item.text ?? null} />
					</div>
				</div>
			))}
		</div>
	)
}
