import cn from 'clsx'
import Image from 'next/image'
import { ItemTypes } from '../../generated/content'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { Cover } from './Cover'
import s from './TopicPage.module.sass'

export type TopicProps = {
	topicPage: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['topicPage']>
}

export function TopicPage(props: CommonPageProps & TopicProps) {
	return (
		<div className={s.TopicPage}>
			{props.topicPage.cover && <Cover cover={props.topicPage.cover} />}
			<div className={s.Topics}>
				{props.topicPage.topics.map((item, i) => (
					<a
						href={item.topic?.localesByLocale?.link?.url}
						className={cn(s.Topic, item.type === ItemTypes.doubled ? s.TypeDouble : '')}
						key={i}>
						<div className={s.TextBox}>
							<div className={s.TitleBox}>
								<span className={s.Title}>{item.topic?.localesByLocale?.cover?.headlineTop}</span>
								{item.topic?.localesByLocale?.cover?.headlineBottom && (
									<span className={s.Title}>—</span>
								)}
								<span className={s.Title}>
									{item.topic?.localesByLocale?.cover?.headlineBottom}
								</span>
							</div>
							<p className={s.Button}>Celé téma</p>
						</div>
						<div className={s.Image}>
							{item?.image?.url && (
								<Image alt="" src={item.image.url} objectFit="contain" layout="fill" />
							)}
						</div>
					</a>
				))}
				{props.topicPage.externals.map((item, i) => (
					<a
						href={item.url}
						className={cn(s.Topic, item.type === ItemTypes.doubled ? s.TypeDouble : '')}
						key={i}>
						<div className={s.TextBox}>
							<div className={s.TitleBox}>
								<span className={s.Title}>{item.text}</span>
								{item.textBottom && <span className={s.Title}>—</span>}
								<span className={s.Title}>{item.textBottom}</span>
							</div>
							<p className={s.Button}>Celé téma</p>
						</div>
						<div className={s.Image}>
							{item?.image?.url && (
								<Image alt="" src={item.image.url} objectFit="contain" layout="fill" />
							)}
						</div>
					</a>
				))}
			</div>
		</div>
	)
}
